import * as React from 'react';

import {
  ApartmentOutlined,
  AppstoreOutlined,
  CommentOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  NodeIndexOutlined,
  PieChartOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  TagsOutlined,
  TeamOutlined,
  UngroupOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Flex, Menu } from 'antd';
import { useLocation } from 'react-router-dom';

import MinLogo from 'assets/images/logo-icon.png';
import { AntdRouterLink, KashilandLogo } from 'components';
import { PATH } from 'utils';

const {
  DEFAULT: { SLUG_SEPARATOR },
  LOCAL: {
    HOME,
    PRODUCTS,
    ORDERS,
    MARKETING,
    USERS,
    GLOBAL_EDIT,
    CATEGORIES,
    BRANDS,
    COMMENTS_MANAGEMENT,
    SEO_SETTING,
    LAYOUT_SETTING,
    REPORTS,
    LOGISTIC,
    ATTRIBUTES,
    PACKAGE_MANAGEMENT,
    PACKAGING_MODELS,
  },
} = PATH;

type PrivateSidebarProps = {
  collapsed: boolean;
};

const PrivateSidebar = ({ collapsed }: PrivateSidebarProps) => {
  const { pathname } = useLocation();

  const splitedPath = pathname.split(SLUG_SEPARATOR).filter(Boolean);
  const selectedKeys = (
    splitedPath.length > 0 ? splitedPath : [SLUG_SEPARATOR]
  ).map(
    (_, index) =>
      `${SLUG_SEPARATOR}${splitedPath.slice(0, index + 1).join(SLUG_SEPARATOR)}`,
  );

  const defaultIconProps: React.ComponentPropsWithoutRef<'span'> = {
    style: { fontSize: '1rem', marginInlineStart: '0.25rem' },
  };

  return (
    <>
      <Flex justify='center' align='center' className='mb-6 py-3'>
        <KashilandLogo
          src={collapsed ? MinLogo : undefined}
          width={collapsed ? 40 : undefined}
          height={collapsed ? 15 : undefined}
        />
      </Flex>

      <Menu
        mode='inline'
        items={[
          {
            label: <AntdRouterLink to={HOME.BASE_URL}>داشبورد</AntdRouterLink>,
            key: HOME.BASE_URL,
            icon: <DashboardOutlined {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink to={PRODUCTS.BASE_URL}>محصولات</AntdRouterLink>
            ),
            key: PRODUCTS.BASE_URL,
            icon: <ShoppingOutlined {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink disabled to={ORDERS.BASE_URL}>
                سفارشات
              </AntdRouterLink>
            ),
            key: ORDERS.BASE_URL,
            icon: <ShoppingCartOutlined {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink disabled to={MARKETING.BASE_URL}>
                بازاریابی
              </AntdRouterLink>
            ),
            key: MARKETING.BASE_URL,
            icon: <TagsOutlined {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink disabled to={USERS.BASE_URL}>
                کاربران
              </AntdRouterLink>
            ),
            key: USERS.BASE_URL,
            icon: <TeamOutlined {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink disabled to={GLOBAL_EDIT.BASE_URL}>
                ویرایش انبوه
              </AntdRouterLink>
            ),
            key: GLOBAL_EDIT.BASE_URL,
            icon: <UngroupOutlined {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink to={CATEGORIES.BASE_URL}>
                دسته‌بندی‌ها
              </AntdRouterLink>
            ),
            key: CATEGORIES.BASE_URL,
            icon: <ApartmentOutlined {...defaultIconProps} />,
          },
          {
            label: <AntdRouterLink to={BRANDS.BASE_URL}>برندها</AntdRouterLink>,
            key: BRANDS.BASE_URL,
            icon: <AppstoreOutlined {...defaultIconProps} />,
          },
          {
            label: 'مدیریت نظرات',
            key: COMMENTS_MANAGEMENT.BASE_URL,
            icon: <CommentOutlined {...defaultIconProps} />,
            children: [
              {
                label: (
                  <AntdRouterLink
                    to={`${COMMENTS_MANAGEMENT.BASE_URL}/${COMMENTS_MANAGEMENT.PRODUCTS_COMMENTS.BASE_URL}`}
                  >
                    دیدگاه کاربران
                  </AntdRouterLink>
                ),
                key: `${COMMENTS_MANAGEMENT.BASE_URL}/${COMMENTS_MANAGEMENT.PRODUCTS_COMMENTS.BASE_URL}`,
              },
              {
                label: (
                  <AntdRouterLink
                    disabled
                    to={`${COMMENTS_MANAGEMENT.BASE_URL}/${COMMENTS_MANAGEMENT.QUESTIONS.BASE_URL}`}
                  >
                    پرسش و پاسخ
                  </AntdRouterLink>
                ),
                key: `${COMMENTS_MANAGEMENT.BASE_URL}/${COMMENTS_MANAGEMENT.QUESTIONS.BASE_URL}`,
                disabled: true,
              },
            ],
          },
          {
            label: (
              <AntdRouterLink disabled to={SEO_SETTING.BASE_URL}>
                تنظیمات سئو
              </AntdRouterLink>
            ),
            key: SEO_SETTING.BASE_URL,
            icon: <FileSearchOutlined {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: 'تنظیمات قالب',
            key: LAYOUT_SETTING.BASE_URL,
            icon: <SettingOutlined {...defaultIconProps} />,
            children: [
              {
                label: (
                  <AntdRouterLink
                    disabled
                    to={`${LAYOUT_SETTING.BASE_URL}/${LAYOUT_SETTING.MEGA_MENUS.BASE_URL}`}
                  >
                    مگامنوها
                  </AntdRouterLink>
                ),
                key: `${LAYOUT_SETTING.BASE_URL}/${LAYOUT_SETTING.MEGA_MENUS.BASE_URL}`,
                disabled: true,
              },
            ],
          },
          {
            label: (
              <AntdRouterLink disabled to={REPORTS.BASE_URL}>
                گزارشات
              </AntdRouterLink>
            ),
            key: REPORTS.BASE_URL,
            icon: <PieChartOutlined {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink disabled to={LOGISTIC.BASE_URL}>
                لجستیک
              </AntdRouterLink>
            ),
            key: LOGISTIC.BASE_URL,
            icon: <NodeIndexOutlined {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink to={ATTRIBUTES.BASE_URL}>فیلد‌ها</AntdRouterLink>
            ),
            key: ATTRIBUTES.BASE_URL,
            icon: <UnorderedListOutlined {...defaultIconProps} />,
          },
          {
            label: (
              <AntdRouterLink disabled to={PACKAGE_MANAGEMENT.BASE_URL}>
                مدیریت پکیج
              </AntdRouterLink>
            ),
            key: PACKAGE_MANAGEMENT.BASE_URL,
            icon: <FolderOpenOutlined {...defaultIconProps} />,
            disabled: true,
          },
          {
            label: (
              <AntdRouterLink to={PACKAGING_MODELS.BASE_URL}>
                بسته‌بندی‌ها
              </AntdRouterLink>
            ),
            key: PACKAGING_MODELS.BASE_URL,
            icon: <DeploymentUnitOutlined {...defaultIconProps} />,
          },
        ]}
        selectedKeys={selectedKeys}
      />
    </>
  );
};

export { PrivateSidebar };
