import { Fetch } from 'lib';
import type { IdentityToken } from 'types';

type RequestResponses = IdentityToken;

type RequestParameters = {};

type RequestBody = Pick<IdentityToken, 'refreshToken'>;

const refreshToken = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/refreshToken',
    method: 'PATCH',
    body,
  });

export { refreshToken };
