import * as React from 'react';

import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useRequest, useUpdateEffect } from 'ahooks';
import {
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Image,
  Modal,
  notification,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';

import { AntdRouterLink } from 'components';
import { deleteProduct, getBrands, getCategories } from 'services';
import type { ProductStatus, ProductSummary } from 'types';
import {
  adjustPageNumber,
  dateFormat,
  decimalFormat,
  generateFileUrl,
  generateSelectOptions,
  generateTableDataSource,
  localizeProductStatus,
  normalizeToString,
  PATH,
  UNITS,
} from 'utils';

const { PRICE } = UNITS;
const {
  LOCAL: { PRODUCTS },
} = PATH;

const productStatuses = [
  'Submitted',
  'Draft',
  'Private',
] satisfies ProductStatus[];

type ProductsTableProps = {
  loading: boolean;
  total: number;
  products: ProductSummary[];
  pageSize: number;
  pageNumber: number;
  handleCategoriesIds: (categoriesIds: string[]) => void;
  handleBrandsIds: (brandsIds: string[]) => void;
  handleStatuses: (statuses: ProductStatus[]) => void;
  handleCallForPrice: (callForPrice: boolean) => void;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const ProductsTable = React.memo<ProductsTableProps>(
  ({
    loading,
    total,
    products,
    pageSize,
    pageNumber,
    handleCategoriesIds,
    handleBrandsIds,
    handleStatuses,
    handleCallForPrice,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: ProductsTableProps) => {
    const [modal, contextHolder] = Modal.useModal();
    const { data: categoriesData, loading: categoriesLoading } = useRequest(
      getCategories,
      {
        cacheKey: 'categories',
      },
    );
    const { data: brandsData, loading: brandsLoading } = useRequest(getBrands, {
      cacheKey: 'brands',
    });

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeleteProduct = async (id: string) => {
      deleteProduct({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const categories = categoriesData?.categories ?? [];
    const brands = brandsData?.brands ?? [];

    const productsDataSource = generateTableDataSource(products, 'id');
    const categoriesSelectOptions = generateSelectOptions(
      categories,
      'id',
      'title',
      'id',
    );
    const brandsSelectOptions = generateSelectOptions(
      brands,
      'id',
      'title',
      'id',
    );

    return (
      <Table<ProductSummary>
        tableLayout='fixed'
        scroll={{ x: 1700, y: 630 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'name',
            key: 'name',
            width: '23%',
            ellipsis: true,
            render: (_, { id, name, bannerId }) => (
              <Space>
                <Image
                  src={generateFileUrl('Product', bannerId, 900, 900)}
                  width={38}
                  height={38}
                />
                <Tooltip title={name}>
                  <AntdRouterLink
                    ellipsis
                    to={`${PRODUCTS.UPDATE_URL}/${id}`}
                    style={{ width: 300 }}
                  >
                    {name}
                  </AntdRouterLink>
                </Tooltip>
              </Space>
            ),
          },
          {
            title: 'دسته‌بندی',
            dataIndex: 'mainCategory',
            key: 'mainCategory',
            ellipsis: true,
            filterDropdown: () => (
              <div
                style={{ background: 'var(--ant-table-header-bg)', padding: 8 }}
              >
                <Select<string[]>
                  showSearch
                  mode='multiple'
                  maxTagCount='responsive'
                  className='w-72'
                  placeholder='دسته‌بندی مورد نظر را انتخاب کنید'
                  loading={categoriesLoading}
                  options={categoriesSelectOptions}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    handleCategoriesIds(value);
                  }}
                />
              </div>
            ),
            render: (_, { mainCategory }) => mainCategory?.title,
          },
          {
            title: 'برند',
            dataIndex: 'brand',
            key: 'brand',
            ellipsis: true,
            filterDropdown: () => (
              <div
                style={{ background: 'var(--ant-table-header-bg)', padding: 8 }}
              >
                <Select<string[]>
                  showSearch
                  mode='multiple'
                  maxTagCount='responsive'
                  className='w-72'
                  placeholder='برند مورد نظر را انتخاب کنید'
                  loading={brandsLoading}
                  options={brandsSelectOptions}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    handleBrandsIds(value);
                  }}
                />
              </div>
            ),
            render: (_, { brand }) => brand?.title,
          },
          {
            title: 'کل موجودی',
            dataIndex: 'totalStock',
            key: 'totalStock',
            ellipsis: true,
            render: (_, { totalStock }) => totalStock,
          },
          {
            title: 'قیمت',
            dataIndex: 'mainSubProduct',
            key: 'mainSubProduct',
            ellipsis: true,
            render: (_, { mainSubProduct }) =>
              decimalFormat(mainSubProduct?.price ?? 0, PRICE),
          },
          {
            title: 'وضعیت انتشار',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            filterDropdown: () => (
              <div
                style={{ background: 'var(--ant-table-header-bg)', padding: 8 }}
              >
                <Select<ProductStatus[]>
                  showSearch
                  mode='multiple'
                  maxTagCount='responsive'
                  className='w-72'
                  placeholder='وضعیت مورد نظر را انتخاب کنید'
                  options={productStatuses.map(type => ({
                    label: localizeProductStatus(type).label,
                    value: type,
                  }))}
                  filterOption={(inputValue, option) =>
                    normalizeToString(option?.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    handleStatuses(value);
                  }}
                />
              </div>
            ),
            render: (_, { status }) => (
              <Flex justify='center'>
                <Badge
                  color={localizeProductStatus(status).color}
                  count={localizeProductStatus(status).label}
                />
              </Flex>
            ),
          },
          {
            title: 'استعلام بها',
            dataIndex: 'callForPrice',
            key: 'callForPrice',
            ellipsis: true,
            filterMultiple: false,
            filterDropdown: () => (
              <div
                style={{ background: 'var(--ant-table-header-bg)', padding: 8 }}
              >
                <Select<boolean>
                  allowClear
                  className='w-72'
                  placeholder='استعلام مورد نظر را انتخاب کنید'
                  options={[
                    {
                      label: 'نیاز به استعلام بها دارد',
                      value: 'true',
                    },
                    {
                      label: 'نیاز به استعلام بها ندارد',
                      value: 'false',
                    },
                  ]}
                  onChange={value => {
                    handleCallForPrice(value);
                  }}
                />
              </div>
            ),
            render: (_, { callForPrice }) => (
              <Flex justify='center'>
                <Checkbox defaultChecked={callForPrice} disabled />
              </Flex>
            ),
          },

          {
            title: 'تاریخ ساخت',
            dataIndex: 'createdDate',
            key: 'createdDate',
            ellipsis: true,
            render: (_, { createdDate }) => dateFormat(new Date(createdDate)),
          },
          {
            title: 'تاریخ آخرین ویرایش',
            dataIndex: 'lastModifiedDate',
            key: 'lastModifiedDate',
            ellipsis: true,
            render: (_, { lastModifiedDate }) =>
              dateFormat(new Date(lastModifiedDate)),
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, name }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-product',
                        label: (
                          <AntdRouterLink
                            to={`${PRODUCTS.UPDATE_URL}/${id}`}
                            className='inline-block py-1 !text-inherit'
                          >
                            <EditOutlined />
                            <span className='ms-2 text-sm'>ویرایش محصول</span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-product',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='!flex items-center'
                              style={{ paddingInline: 0, marginBlock: 2 }}
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف محصول "${name}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteProduct(id);
                                }
                              }}
                            >
                              <DeleteOutlined />
                              <span className='text-sm'>حذف محصول</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                  placement='bottomLeft'
                >
                  <Button size='small' className='!py-3.5'>
                    <MoreOutlined style={{ fontSize: 20 }} />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={productsDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:!h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
ProductsTable.displayName = 'ProductsTable';

export { ProductsTable };
