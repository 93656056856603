import { Fetch } from 'lib';
import type { Product } from 'types';

type RequestResponses = Product;

type RequestParameters = {
  id: string;
};

const getProduct = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminProduct/getProduct',
    params,
  });

export { getProduct };
