import { Fetch } from 'lib';
import type { IdentityPayload } from 'types';

type RequestResponses = Pick<IdentityPayload, 'ticket'>;

type RequestParameters = {};

type RequestBody = Pick<IdentityPayload, 'phoneNumber'>;

const changePhoneNumber = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/changePhoneNumber',
    method: 'PATCH',
    body,
  });

export { changePhoneNumber };
