import { Fetch } from 'lib';
import { createUser } from 'services';

type RequestResponses = Awaited<ReturnType<typeof createUser>>;

type RequestParameters = {
  id: string;
};

type RequestBody = Parameters<typeof createUser>[number];

const editUser = async (params: RequestParameters, body: RequestBody) =>
  await Fetch<
    RequestResponses,
    Omit<RequestParameters, 'commentId '>,
    RequestBody
  >({
    url: 'adminUser/editUser',
    method: 'PUT',
    params,
    body,
  });

export { editUser };
