import { Fetch } from 'lib';
import type { BrandSortType, BrandSummary } from 'types';

type RequestResponses = {
  total: number;
  data: BrandSummary[];
};

type RequestParameters = {
  sortType: BrandSortType;
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchBrands = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminBrand/searchBrands',
    params,
  });

export { searchBrands };
