import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { ProductFormContext } from 'context';
import type { ComponentMode, Product as ProductConfig } from 'types';

import { ProductForm, ProductHeader } from './components';

type ProductProps = {
  mode: ComponentMode;
};

export default function Product({ mode }: ProductProps) {
  const productData = useLoaderData() as ProductConfig;

  const isUpdatedProduct = mode === 'Update';
  const productTitle = isUpdatedProduct
    ? productData.name
    : 'افزودن محصول جدید';
  const defaultName = isUpdatedProduct ? productData.name : '';
  const defaultStatus = isUpdatedProduct ? productData.status : 'Submitted';

  return (
    <>
      <Helmet>
        <title>{`صفحه ${productTitle} | کاشی لند`}</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <ProductHeader>{productTitle}</ProductHeader>

      <ProductFormContext
        mode={mode}
        step={1}
        defaultName={defaultName}
        defaultStatus={defaultStatus}
        {...(isUpdatedProduct && productData)}
      >
        <ProductForm />
      </ProductFormContext>
    </>
  );
}
