import { useContext } from 'react';

import {
  PackagingModelActionContext,
  PackagingModelValueContext,
} from './context';

const usePackagingModelValue = () => {
  const context = useContext(PackagingModelValueContext);

  if (!context) {
    throw new Error(
      'usePackagingModelValue must be used within a <PackagingModelFormContext />',
    );
  }

  return context;
};

const usePackagingModelAction = () => {
  const context = useContext(PackagingModelActionContext);

  if (!context) {
    throw new Error(
      'usePackagingModelAction must be used within a <PackagingModelFormContext />',
    );
  }

  return context;
};

export { usePackagingModelAction, usePackagingModelValue };
