import type {
  AttributeConfigType,
  ProductCommentStatus,
  ProductStatus,
  ProductType,
} from 'types';

const localizeAttributeType = (type?: AttributeConfigType) => {
  switch (type) {
    case 'Text':
      return { label: 'متن' };
    case 'Number':
      return { label: 'عدد' };
    case 'Select':
      return { label: 'تک گزینه‌ایی' };
    case 'CheckBox':
      return { label: 'چند گزینه‌ایی' };
    default:
      return { label: undefined };
  }
};

const localizeProductType = (type?: ProductType) => {
  switch (type) {
    case 'Normal':
      return { label: 'ثابت' };
    case 'Variable':
      return { label: 'متغیر' };
    default:
      return { label: undefined };
  }
};

const localizeProductStatus = (status?: ProductStatus) => {
  switch (status) {
    case 'Draft':
      return { label: 'پیش‌نویس', color: 'yellow' };
    case 'Private':
      return { label: 'خصوصی', color: 'purple' };
    case 'Submitted':
      return { label: 'منتشر شده', color: 'green' };
    default:
      return { label: undefined, color: undefined };
  }
};

const localizeProductCommentStatus = (status?: ProductCommentStatus) => {
  switch (status) {
    case 'OnReview':
      return { label: 'در حال بررسی', color: 'yellow' };
    case 'Rejected':
      return { label: 'رد شده', color: 'red' };
    case 'Accepted':
      return { label: 'تایید شده', color: 'green' };
    default:
      return { label: undefined, color: undefined };
  }
};

const localizeSubProductActivateStock = (activateStock?: boolean) => {
  switch (activateStock) {
    case true:
      return { label: 'فعال' };
    default:
      return { label: 'غیرفعال' };
  }
};

export {
  localizeAttributeType,
  localizeProductCommentStatus,
  localizeProductStatus,
  localizeProductType,
  localizeSubProductActivateStock,
};
