import * as React from 'react';

import { MobileOutlined } from '@ant-design/icons';
import { useLockFn, useSafeState } from 'ahooks';
import { Button, Form, Input, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { useLoginAction, useLoginValue } from 'context';
import { sendSigninOtp } from 'services';
import { inputRule } from 'utils';

type FieldType = Pick<ReturnType<typeof useLoginValue>, 'phoneNumber'>;

const PhoneNumberForm = () => {
  const [message, setMessage] = useSafeState<string>();
  const [, setSearchParams] = useSearchParams();
  const [form] = Form.useForm<FieldType>();
  const action = useLoginAction();

  const onFinish = useLockFn(async ({ phoneNumber = '' }: FieldType) => {
    sendSigninOtp({ phoneNumber })
      .then(({ ticket }) => {
        action({ type: 'UPDATE_LOGIN_PHONE_NUMBER', payload: { phoneNumber } });
        action({ type: 'UPDATE_LOGIN_TICKET', payload: { ticket } });
      })
      .catch(message => {
        setMessage(message);
      });
  });

  return (
    <div className='basis-1/2 space-y-8'>
      <Typography.Title level={2} className='!text-xl !font-bold'>
        ورود با رمز یک‌بار مصرف
      </Typography.Title>

      <Form
        form={form}
        layout='vertical'
        requiredMark={false}
        autoComplete='off'
        onFinish={onFinish}
      >
        <Form.Item<FieldType>
          label='شماره موبایل'
          name='phoneNumber'
          rules={[inputRule.required, inputRule.phoneNumber]}
        >
          <Input
            placeholder='برای مثال ۰۹۱۲۳۴۵۶۷۸۹'
            prefix={
              <MobileOutlined
                className='text-lg'
                style={{ color: 'var(--ant-color-text-placeholder)' }}
              />
            }
          />
        </Form.Item>

        <Typography.Paragraph
          type='danger'
          className='!mb-2 -mt-4 px-2 empty:my-12'
        >
          {message}
        </Typography.Paragraph>

        <Form.Item className='!mb-4'>
          <Button type='primary' htmlType='submit' block>
            ادامه
          </Button>
        </Form.Item>

        <Form.Item className='!mb-0'>
          <Button block onClick={() => setSearchParams('', { replace: true })}>
            ورود با رمز ثابت
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { PhoneNumberForm };
