'use client';

import * as React from 'react';

import Logo from 'assets/images/logo.png';
import { AntdRouterLink, LazyLoadImage } from 'components';
import { PATH } from 'utils';

const {
  LOCAL: { HOME },
} = PATH;

type KashilandLogoProps = Partial<
  Pick<React.ComponentPropsWithoutRef<typeof AntdRouterLink>, 'to'> &
    React.ComponentPropsWithoutRef<typeof LazyLoadImage>
>;

const KashilandLogo = React.forwardRef<
  React.ElementRef<typeof AntdRouterLink>,
  KashilandLogoProps
>(
  (
    {
      to = HOME.BASE_URL,
      src = Logo,
      width = 150,
      height = 25,
      effect = 'blur',
      alt = 'لوگوی کاشی لند',
      ...props
    },
    ref,
  ) => (
    <AntdRouterLink ref={ref} to={to}>
      <LazyLoadImage
        src={src}
        width={width}
        height={height}
        effect={effect}
        alt={alt}
        {...props}
      />
    </AntdRouterLink>
  ),
);
KashilandLogo.displayName = 'KashilandLogo';

export { KashilandLogo };
