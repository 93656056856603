import * as React from 'react';

import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useUpdateEffect } from 'ahooks';
import {
  Badge,
  Button,
  Dropdown,
  Flex,
  Image,
  Modal,
  notification,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';

import { AntdRouterLink } from 'components';
import { deleteProductComment } from 'services';
import type { ProductComment } from 'types';
import {
  adjustPageNumber,
  dateFormat,
  generateFileUrl,
  generateTableDataSource,
  localizeProductCommentStatus,
  PATH,
} from 'utils';

const {
  LOCAL: {
    COMMENTS_MANAGEMENT: { PRODUCTS_COMMENTS },
  },
} = PATH;

type ProductsCommentsTableProps = {
  loading: boolean;
  total: number;
  productsComments: ProductComment[];
  pageSize: number;
  pageNumber: number;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const ProductsCommentsTable = React.memo<ProductsCommentsTableProps>(
  ({
    loading,
    total,
    productsComments,
    pageSize,
    pageNumber,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: ProductsCommentsTableProps) => {
    const [modal, contextHolder] = Modal.useModal();

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeleteProductComment = async (id: string) => {
      deleteProductComment({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const productsCommentsDataSource = generateTableDataSource(
      productsComments,
      'id',
    );

    return (
      <Table<ProductComment>
        tableLayout='fixed'
        scroll={{ x: 1200, y: 630 }}
        columns={[
          {
            title: 'نام محصول',
            dataIndex: 'product',
            key: 'product',
            width: '23%',
            ellipsis: true,
            render: (_, { product: { name, bannerId } }) => (
              <Space>
                <Image
                  src={generateFileUrl('Product', bannerId, 900, 900)}
                  width={38}
                  height={38}
                />
                <Tooltip title={name}>
                  <Typography.Text ellipsis style={{ width: 275 }}>
                    {name}
                  </Typography.Text>
                </Tooltip>
              </Space>
            ),
          },
          {
            title: 'نام کاربر',
            dataIndex: 'user',
            key: 'user',
            width: 150,
            ellipsis: true,
            render: (_, { user: { firstName, lastName } }) =>
              `${firstName} ${lastName}`,
          },
          {
            title: 'دیدگاه',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true,
            render: (_, { content }) => (
              <Typography dangerouslySetInnerHTML={{ __html: content }} />
            ),
          },
          {
            title: 'تاریخ دیدگاه',
            dataIndex: 'publishTime',
            key: 'publishTime',
            width: 125,
            ellipsis: true,
            render: (_, { publishTime }) =>
              publishTime && dateFormat(new Date(publishTime)),
          },
          {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
            width: 125,
            ellipsis: true,
            render: (_, { status }) => (
              <Flex justify='center'>
                <Badge
                  color={localizeProductCommentStatus(status).color}
                  count={localizeProductCommentStatus(status).label}
                />
              </Flex>
            ),
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, content }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-product-comment',
                        label: (
                          <AntdRouterLink
                            to={`${PRODUCTS_COMMENTS.UPDATE_URL}/${id}`}
                            className='inline-block py-1 !text-inherit'
                          >
                            <EditOutlined />
                            <span className='ms-2 text-sm'>ویرایش دیدگاه</span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-product-comment',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='!flex items-center'
                              style={{ paddingInline: 0, marginBlock: 2 }}
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف دیدگاه "${content}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteProductComment(id);
                                }
                              }}
                            >
                              <DeleteOutlined />
                              <span className='text-sm'>حذف دیدگاه</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                  placement='bottomLeft'
                >
                  <Button size='small' className='!py-3.5'>
                    <MoreOutlined style={{ fontSize: 20 }} />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={productsCommentsDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:!h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
ProductsCommentsTable.displayName = 'ProductsCommentsTable';

export { ProductsCommentsTable };
