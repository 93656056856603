import type { FileInfo, ResponseErrors } from 'types';
import { generateRequestUrl, generateResponseError } from 'utils';

type RequestConfig<P> = {
  method?: RequestConfigMethod;
  url: string;
  params?: P;
  async?: boolean;
  username?: string;
  password?: string;
  file: Blob;
  onProgress?: (percent: number) => void;
};

type RequestConfigMethod = 'POST';

const Upload = <T = FileInfo, P extends object = object>({
  method = 'POST',
  url,
  params,
  async = true,
  username,
  password,
  file,
  onProgress,
}: RequestConfig<P>) =>
  new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const input = generateRequestUrl<P>(url, params);
    const body = new FormData();
    body.append('file', file);

    if (onProgress) {
      xhr.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          const percent = (event.loaded / event.total) * 100;
          onProgress(percent);
        }
      });
    }

    xhr.addEventListener('loadend', () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const data = JSON.parse(xhr.response);
        resolve(data as T);
      } else {
        const error = JSON.parse(xhr.response);
        const message = generateResponseError(error as ResponseErrors);
        reject(message);
      }
    });

    xhr.addEventListener('error', () => {
      const error = JSON.parse(xhr.response);
      const message = generateResponseError(error as ResponseErrors);
      reject(message);
    });

    xhr.open(method, input, async, username, password);
    xhr.withCredentials = false;
    xhr.send(body);
  });

export { Upload };
