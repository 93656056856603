import * as React from 'react';

import {
  BellOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, Flex, Space } from 'antd';

import { AntdRouterLink } from 'components';

type PrivateHeaderProps = {
  collapsed: boolean;
  handleCollapse: () => void;
};

const PrivateHeader = ({ collapsed, handleCollapse }: PrivateHeaderProps) => (
  <Flex justify='space-between' align='center'>
    <Space size={0} align='center'>
      <div>
        <Button
          type='text'
          size='large'
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={handleCollapse}
        />
        <span className='sr-only'>آیکون منو</span>
      </div>

      <Badge
        count='نسخه 1.0'
        color='var(--ant-color-primary)'
        className='!mb-1'
      />
    </Space>

    <Space>
      <Dropdown
        menu={{
          items: [
            {
              label: <AntdRouterLink to='/'>داشبورد</AntdRouterLink>,
              key: '/',
            },
            {
              type: 'divider',
            },
            {
              label: <AntdRouterLink to='/products'>محصولات</AntdRouterLink>,
              key: '/products',
            },
          ],
        }}
        trigger={['click']}
      >
        <Button type='text'>
          <BellOutlined
            style={{
              fontSize: 20,
            }}
          />
          <span className='sr-only'>آیکون پیام</span>
        </Button>
      </Dropdown>

      <Dropdown
        menu={{
          items: [
            {
              label: <AntdRouterLink to='/'>داشبورد</AntdRouterLink>,
              key: '/',
            },
            {
              type: 'divider',
            },
            {
              label: <AntdRouterLink to='/products'>محصولات</AntdRouterLink>,
              key: '/products',
            },
          ],
        }}
        trigger={['click']}
      >
        <Button type='text' style={{ paddingInline: 0 }}>
          <Space>
            <Avatar
              // TODO: Put user image url in this src attribute
              // src='https://api.dicebear.com/7.x/miniavs/svg?seed=1'
              icon={<UserOutlined />}
            />
          </Space>
        </Button>
      </Dropdown>
    </Space>
  </Flex>
);

export { PrivateHeader };
