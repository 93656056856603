import { Fetch } from 'lib';
import type { PackagingModel, PackagingModelSortType } from 'types';

type RequestResponses = {
  total: number;
  data: PackagingModel[];
};

type RequestParameters = {
  sortType: PackagingModelSortType;
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchPackagingModels = async (params: RequestParameters) =>
  await Fetch<RequestResponses, typeof params>({
    url: 'adminPackagingModel/searchPackagingModels',
    params,
  });

export { searchPackagingModels };
