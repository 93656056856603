import { Fetch } from 'lib';
import type { BaseAttributeSummary, Category } from 'types';

type RequestResponses = Category;

type RequestParameters = {};

type RequestBody = Pick<
  Category,
  'title' | 'subSlug' | 'iconId' | 'description'
> &
  Partial<
    Record<'parentId', string> &
      Record<'relatedAttribute', BaseAttributeSummary>
  >;

const createCategory = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminCategory/createCategory',
    method: 'POST',
    body,
  });

export { createCategory };
