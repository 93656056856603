import * as React from 'react';

import { useLockFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useCategoryValue } from 'context';
import { createCategory, updateCategory } from 'services';
import { formatIdentifier } from 'utils';
import { PATH } from 'utils';

import { CategoryFormAction } from './action';
import { CategoryFormContent } from './content';

const {
  LOCAL: { CATEGORIES },
} = PATH;

type FieldType = unknown;

const CategoryForm = () => {
  const [form] = Form.useForm<FieldType>();
  const {
    id = '',
    title = '',
    parent,
    relatedAttribute,
    subSlug,
    iconId,
    description,
    defaultTitle,
    mode,
  } = useCategoryValue();
  const navigate = useNavigate();

  const formatedSubSlug = formatIdentifier(subSlug ?? title);

  const onFinish = useLockFn(async () => {
    const params: Parameters<typeof updateCategory>[0] = {
      id,
    };
    const body: Parameters<typeof updateCategory>[1] = {
      title,
      parentId: parent?.id,
      relatedAttribute: relatedAttribute && {
        id: relatedAttribute.id,
        selectedValues: relatedAttribute.selectedValues,
      },
      subSlug: formatedSubSlug,
      iconId,
      description,
    };

    switch (mode) {
      case 'Create': {
        return createCategory(body)
          .then(() => {
            notification.success({
              message: `دسته‌بندی‌ ${title} با موفقیت ساخته شد.`,
            });
            navigate(CATEGORIES.BASE_URL);
          })
          .catch(message => {
            notification.error({ message });
          });
      }
      case 'Update': {
        return updateCategory(params, body)
          .then(() => {
            notification.success({
              message: `دسته‌بندی‌ ${defaultTitle} با موفقیت ویرایش شد.`,
            });
            navigate(CATEGORIES.BASE_URL);
          })
          .catch(message => {
            notification.error({ message });
          });
      }
      default:
        break;
    }
  });

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      autoComplete='off'
      style={{
        marginBlock: 12,
      }}
      fields={[
        {
          name: 'title',
          value: title,
        },
        {
          name: 'parent',
          value: parent && {
            label: parent.title,
            value: parent.id,
          },
        },
        {
          name: 'relatedAttribute',
          value: parent &&
            relatedAttribute && {
              label: relatedAttribute.name,
              value: relatedAttribute.id,
            },
        },
        {
          name: 'relatedAttributeValue',
          value:
            parent && relatedAttribute && relatedAttribute.selectedValues.at(0),
        },
        {
          name: 'subSlug',
          value: formatedSubSlug,
        },
      ]}
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col lg:items-start' gap='large'>
        <CategoryFormContent />
        <CategoryFormAction />
      </Flex>
    </Form>
  );
};

export { CategoryForm };
