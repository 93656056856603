// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[5].use[2]!../fonts/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-iran-yekan: 'iran-yekan';
}

html {
  font-family: var(--font-iran-yekan) !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/base.css"],"names":[],"mappings":"AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,8CAA8C;AAChD","sourcesContent":["@import url('../fonts');\n\n:root {\n  --font-iran-yekan: 'iran-yekan';\n}\n\nhtml {\n  font-family: var(--font-iran-yekan) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
