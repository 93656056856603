import * as React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Divider,
  Flex,
  Form,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { useProductCommentValue } from 'context';
import { deleteProductComment } from 'services';
import type { ProductCommentStatus } from 'types';
import { localizeProductCommentStatus } from 'utils';
import { PATH } from 'utils';

const {
  LOCAL: { COMMENTS_MANAGEMENT },
} = PATH;

const productCommentStatuses = [
  'Accepted',
  'Rejected',
  'OnReview',
] satisfies ProductCommentStatus[];

type FieldType = Pick<ReturnType<typeof useProductCommentValue>, 'status'>;

const ProductCommentFormAction = () => {
  const [modal, contextHolder] = Modal.useModal();
  const {
    id = '',
    defaultName,
    defaultStatus,
    mode,
  } = useProductCommentValue();
  const navigate = useNavigate();

  const handleDeleteProductComment = async (id: string) => {
    deleteProductComment({ id })
      .then(() => {
        navigate(
          `${COMMENTS_MANAGEMENT.BASE_URL}/${COMMENTS_MANAGEMENT.PRODUCTS_COMMENTS.BASE_URL}`,
        );
      })
      .catch(message => {
        notification.error({ message });
      });
  };

  return (
    <div className='rounded bg-white lg:basis-[30%]'>
      <div className='px-6 pt-4'>
        <Typography.Title level={3} className='!mb-0 !text-base !font-medium'>
          وضعیت انتشار
        </Typography.Title>
      </div>

      <Divider style={{ marginBlockStart: 15, marginBlockEnd: 30 }} />

      <div className='px-6'>
        <Typography.Paragraph>
          <Badge
            color={localizeProductCommentStatus(defaultStatus).color}
            text={`وضعیت فعلی: ${localizeProductCommentStatus(defaultStatus).label}`}
          />
        </Typography.Paragraph>

        <Form.Item<FieldType> label='وضعیت جدید' name='status'>
          <Select
            placeholder='نوع وضعیت را انتخاب کنید'
            options={productCommentStatuses.map(type => ({
              label: localizeProductCommentStatus(type).label,
              value: type,
            }))}
          />
        </Form.Item>
      </div>

      <Flex gap='small' className='p-6 max-lg:flex-col lg:justify-end'>
        <React.Fragment>
          {mode === 'Update' ? (
            <Form.Item className='!mb-0'>
              <Button
                danger
                type='primary'
                className='!flex items-center max-lg:w-full'
                onClick={async () => {
                  const confirmed = await modal.confirm({
                    title: `آیا از حذف دیدگاه "${defaultName}" اطمینان دارید؟`,
                    width: 500,
                  });

                  if (confirmed) {
                    handleDeleteProductComment(id);
                  }
                }}
              >
                <DeleteOutlined />
                <span className='text-sm'>حذف دیدگاه</span>
              </Button>

              {contextHolder}
            </Form.Item>
          ) : null}
        </React.Fragment>

        <Form.Item className='!mb-0'>
          <Button type='primary' htmlType='submit' className='max-lg:w-full'>
            {mode === 'Create' ? 'افزودن دیدگاه جدید' : 'ذخیره تغییرات'}
          </Button>
        </Form.Item>
      </Flex>
    </div>
  );
};

export { ProductCommentFormAction };
