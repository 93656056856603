import * as React from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const ProductCommentHeader = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const navigate = useNavigate();

  return (
    <Space size={2}>
      <Button
        type='text'
        size='small'
        onClick={() => navigate(-1)}
        icon={
          <>
            <RightOutlined style={{ fontSize: 10, strokeWidth: 2 }} />
            <span className='sr-only'>آیکون برگشت</span>
          </>
        }
      />

      <Typography.Text className='font-semibold'>{children}</Typography.Text>
    </Space>
  );
};

export { ProductCommentHeader };
