import type { Brand, ComponentMode } from 'types';

type State = Partial<
  Brand & Record<'mode', ComponentMode> & Record<'defaultTitle', string>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_BRAND_TITLE';
      payload: Pick<typeof initialState, 'title'>;
    }
  | {
      type: 'UPDATE_BRAND_BANNER_ID';
      payload: Pick<typeof initialState, 'bannerId'>;
    }
  | {
      type: 'UPDATE_BRAND_SLUG';
      payload: Pick<typeof initialState, 'slug'>;
    }
  | {
      type: 'UPDATE_BRAND_DESCRIPTION';
      payload: Pick<typeof initialState, 'description'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
