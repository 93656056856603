import * as React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Typography } from 'antd';

import { AntdRouterLink } from 'components';
import { PATH } from 'utils';

const {
  LOCAL: { PACKAGING_MODELS },
} = PATH;

type PackagingModelsHeaderProps = {
  total: number;
  searchKey: string;
  handleSearchKey: (searchKey: string) => void;
};

const PackagingModelsHeader = ({
  total,
  searchKey,
  handleSearchKey,
}: PackagingModelsHeaderProps) => (
  <Flex
    gap={10}
    className='mb-5 max-lg:flex-col lg:items-center lg:justify-between'
  >
    <Typography.Title level={2} className='py-1' style={{ marginBlockEnd: 0 }}>
      <span className='text-base'>بسته‌بندی‌ها</span>
      <span className='text-sm text-slate-400'> ({total})</span>
    </Typography.Title>

    <Flex gap={15} className='max-lg:flex-wrap'>
      <Input.Search
        allowClear
        styles={{
          affixWrapper: {
            height: '2.5rem',
          },
        }}
        placeholder='جستجو بسته‌بندی‌ها'
        defaultValue={searchKey}
        onSearch={value => handleSearchKey(value)}
      />

      <AntdRouterLink
        to={PACKAGING_MODELS.CREATE_URL}
        className='max-lg:basis-full'
      >
        <Button
          type='primary'
          className='w-full'
          icon={<PlusOutlined style={{ fontSize: 12 }} />}
        >
          بسته‌بندی جدید
        </Button>
      </AntdRouterLink>
    </Flex>
  </Flex>
);

export { PackagingModelsHeader };
