import { Fetch } from 'lib';
import type { BrandSummary } from 'types';

type RequestResponses = {
  brands: BrandSummary[];
};

const getBrands = async () =>
  await Fetch<RequestResponses>({
    url: 'adminBrand/getBrands',
  });

export { getBrands };
