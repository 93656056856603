import type { IdentityPayload } from 'types';

type State = Partial<
  Pick<IdentityPayload, 'phoneNumber' | 'password' | 'otp' | 'ticket'>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_LOGIN_PHONE_NUMBER';
      payload: Pick<typeof initialState, 'phoneNumber'>;
    }
  | {
      type: 'UPDATE_LOGIN_TICKET';
      payload: Pick<typeof initialState, 'ticket'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
