import * as React from 'react';

import { useLockFn } from 'ahooks';
import { Flex, Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useBrandValue } from 'context';
import { createBrand, updateBrand } from 'services';
import { formatIdentifier } from 'utils';
import { PATH } from 'utils';

import { BrandFormAction } from './action';
import { BrandFormContent } from './content';

const {
  LOCAL: { BRANDS },
} = PATH;

type FieldType = Pick<ReturnType<typeof useBrandValue>, 'abstraction'>;

const BrandForm = () => {
  const [form] = Form.useForm<FieldType>();
  const {
    id = '',
    title = '',
    slug,
    bannerId,
    description,
    defaultTitle,
    mode,
  } = useBrandValue();
  const navigate = useNavigate();

  const formatedSlug = formatIdentifier(slug ?? title);

  const onFinish = useLockFn(async ({ abstraction }: FieldType) => {
    const params: Parameters<typeof updateBrand>[0] = {
      id,
    };
    const body: Parameters<typeof updateBrand>[1] = {
      slug: formatedSlug,
      title,
      bannerId,
      abstraction,
      description,
    };

    switch (mode) {
      case 'Create': {
        return createBrand(body)
          .then(() => {
            notification.success({
              message: `برند ${title} با موفقیت ساخته شد.`,
            });
            navigate(BRANDS.BASE_URL);
          })
          .catch(message => {
            notification.error({ message });
          });
      }
      case 'Update': {
        return updateBrand(params, body)
          .then(() => {
            notification.success({
              message: `برند ${defaultTitle} با موفقیت ویرایش شد.`,
            });
            navigate(BRANDS.BASE_URL);
          })
          .catch(message => {
            notification.error({ message });
          });
      }
      default:
        break;
    }
  });

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      autoComplete='off'
      style={{
        marginBlock: 12,
      }}
      fields={[
        {
          name: 'title',
          value: title,
        },
        {
          name: 'slug',
          value: formatedSlug,
        },
      ]}
      onFinish={onFinish}
    >
      <Flex className='max-lg:flex-col lg:items-start' gap='large'>
        <BrandFormContent />
        <BrandFormAction />
      </Flex>
    </Form>
  );
};

export { BrandForm };
