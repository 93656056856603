import { Fetch } from 'lib';
import { createAttributeConfig } from 'services';

type RequestResponses = Awaited<ReturnType<typeof createAttributeConfig>>;

type RequestParameters = {
  id: string;
};

type RequestBody = Parameters<typeof createAttributeConfig>[number];

const updateAttributeConfig = async (
  params: RequestParameters,
  body: RequestBody,
) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminAttributeConfig/updateAttributeConfig',
    method: 'PUT',
    params,
    body,
  });

export { updateAttributeConfig };
