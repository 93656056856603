import { createContext } from 'react';
import type { Dispatch } from 'react';

import { initialAction, initialState } from './initial';

const BrandValueContext = createContext<typeof initialState>(initialState);

const BrandActionContext = createContext<Dispatch<typeof initialAction>>(
  () => {},
);

export { BrandActionContext, BrandValueContext };
