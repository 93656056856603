import { Fetch } from 'lib';
import { createPackagingModel } from 'services';

type RequestResponses = Awaited<ReturnType<typeof createPackagingModel>>;

type RequestParameters = {
  id: string;
};

type RequestBody = Parameters<typeof createPackagingModel>[number];

const updatePackagingModel = async (
  params: RequestParameters,
  body: RequestBody,
) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminPackagingModel/updatePackagingModel',
    method: 'PUT',
    params,
    body,
  });

export { updatePackagingModel };
