import * as React from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { useDebounceFn, useRequest } from 'ahooks';
import { Button, Col, Empty, Form, Input, Row, Select, TreeSelect } from 'antd';

import { ImgCropUpload, RichTextEditor } from 'components';
import { useCategoryAction, useCategoryValue } from 'context';
import { getCategories, getCategory } from 'services';
import type { SelectOption, TreeSelectSource } from 'types';
import {
  findElementByKey,
  findTreeElementByKey,
  generateFileUrl,
  generateRequestUrl,
  generateSelectOptions,
  generateTreeSelectData,
  inputRule,
  normalizeToString,
} from 'utils';

type FieldType = Pick<
  ReturnType<typeof useCategoryValue>,
  'title' | 'iconId' | 'parent' | 'relatedAttribute' | 'subSlug' | 'description'
> &
  Record<'relatedAttributeValue', string>;

const CategoryFormContent = () => {
  const { data: categoriesData, loading: categoriesLoading } = useRequest(
    getCategories,
    {
      cacheKey: 'categories',
    },
  );
  const {
    data: parentData,
    loading: parentLoading,
    run,
  } = useRequest(getCategory, {
    manual: true,
  });
  const {
    title = '',
    iconId,
    parent,
    relatedAttribute,
    subSlug = '',
    description,
  } = useCategoryValue();
  const action = useCategoryAction();

  React.useEffect(() => {
    if (parent) {
      run({ id: parent.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent]);

  const { run: debounceUpdateTitle } = useDebounceFn(
    (debouncedTitle: typeof title) => {
      action({
        type: 'UPDATE_CATEGORY_TITLE',
        payload: {
          title: debouncedTitle,
        },
      });
    },
  );
  const { run: debounceUpdateSubSlug } = useDebounceFn(
    (debouncedSubSlug: typeof subSlug) => {
      action({
        type: 'UPDATE_CATEGORY_SUB_SLUG',
        payload: {
          subSlug: debouncedSubSlug,
        },
      });
    },
  );
  const { run: debounceUpdateDescription } = useDebounceFn(
    (debouncedDescription: typeof description) => {
      action({
        type: 'UPDATE_CATEGORY_DESCRIPTION',
        payload: {
          description: debouncedDescription,
        },
      });
    },
  );

  const ownerType = 'Category';
  const uploadAction = generateRequestUrl('adminCategory/uploadCategoryFile');

  const totalCategories = categoriesData?.categories ?? [];
  const selectedAttributes = parentData?.attributes ?? [];
  const relatedParentAttribute = parentData?.relatedAttribute;

  const categoriesTreeData = generateTreeSelectData(
    totalCategories,
    'id',
    'title',
    'id',
  );
  const attributesOptions = generateSelectOptions(
    selectedAttributes,
    'id',
    'name',
    'id',
  );

  return (
    <Row className='rounded bg-white px-6 py-8 lg:basis-[70%]'>
      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='عنوان'
          name='title'
          rules={[inputRule.required, inputRule.string]}
        >
          <Input
            placeholder='عنوان دسته‌بندی را وارد کنید'
            onChange={({ target: { value } }) => {
              debounceUpdateTitle(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='نشان‌واره'
          name='iconId'
          valuePropName='file'
        >
          <ImgCropUpload
            showUploadList
            listType='picture'
            className='[&>.ant-upload-select]:w-full [&_.ant-upload-list-item-thumbnail]:!h-[inherit] [&_.ant-upload-list-item-thumbnail]:rounded-sm [&_.ant-upload-list-item-thumbnail]:py-0.5 [&_.ant-upload-list-item]:!mt-0  [&_.ant-upload-list-item]:!h-10'
            maxCount={1}
            action={uploadAction}
            fileList={
              iconId
                ? [
                    {
                      uid: iconId,
                      name: title,
                      status: 'done',
                      url: generateFileUrl(ownerType, iconId, 200, 200),
                    },
                  ]
                : undefined
            }
            onChange={({ file: { status, response } }) => {
              if (status === 'done' && response) {
                action({
                  type: 'UPDATE_CATEGORY_ICON_ID',
                  payload: {
                    iconId: response.id,
                  },
                });
              }
              if (status === 'removed') {
                action({
                  type: 'UPDATE_CATEGORY_ICON_ID',
                  payload: {
                    iconId: undefined,
                  },
                });
              }
            }}
          >
            {!iconId ? (
              <Button
                icon={<UploadOutlined />}
                className='!w-full !justify-start !text-sm !text-[var(--ant-color-text-placeholder)]'
              >
                نشان‌واره دسته‌بندی را بار‌گذاری کنید
              </Button>
            ) : null}
          </ImgCropUpload>
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType> label='دسته‌بندی مادر' name='parent'>
          <TreeSelect<TreeSelectSource | undefined>
            allowClear
            showSearch
            labelInValue
            placeholder='دسته‌بندی مادر را انتخاب کنید'
            loading={categoriesLoading}
            treeData={categoriesTreeData}
            filterTreeNode={(inputValue, treeNode) =>
              normalizeToString(treeNode.label)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={value => {
              const parentId = normalizeToString(value?.value);
              const selectedParent = findTreeElementByKey(
                totalCategories,
                'id',
                parentId,
              );

              action({
                type: 'UPDATE_CATEGORY_PARENT',
                payload: {
                  parent: selectedParent,
                },
              });
              action({
                type: 'UPDATE_CATEGORY_RELATED_ATTRIBUTE',
                payload: {
                  relatedAttribute: selectedParent?.relatedAttribute,
                },
              });
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType> label='فیلد مورد نظر' name='relatedAttribute'>
          <Select<SelectOption | undefined>
            allowClear
            showSearch
            labelInValue
            placeholder='فیلد مورد نظر را انتخاب کنید'
            disabled={parentLoading || !!relatedParentAttribute}
            loading={parentLoading}
            options={
              parent
                ? relatedParentAttribute
                  ? [
                      {
                        label: relatedParentAttribute.name,
                        value: relatedParentAttribute.id,
                      },
                    ]
                  : attributesOptions
                : undefined
            }
            filterOption={(inputValue, option) =>
              normalizeToString(option?.label)
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onChange={value => {
              const attributeId = normalizeToString(value?.value);
              const selectedAttribute = findElementByKey(
                selectedAttributes,
                'id',
                attributeId,
              );

              action({
                type: 'UPDATE_CATEGORY_RELATED_ATTRIBUTE',
                payload: {
                  relatedAttribute: selectedAttribute && {
                    ...selectedAttribute,
                    selectedValues: [],
                  },
                },
              });
            }}
            {...(!parent && {
              notFoundContent: (
                <Empty
                  className='!my-3'
                  description='ابتدا دسته‌بندی مادر را انتخاب کنید.'
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 'calc(var(--ant-control-height-lg) * 0.875)',
                  }}
                />
              ),
            })}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='مقدار فیلد مورد نظر'
          name='relatedAttributeValue'
        >
          <Select<string | undefined>
            allowClear
            showSearch
            placeholder='مقدار فیلد مورد نظر را انتخاب کنید'
            disabled={parentLoading || !relatedParentAttribute}
            loading={parentLoading}
            options={
              parent && relatedParentAttribute
                ? relatedParentAttribute.values.map(value => ({
                    label: value,
                    value,
                  }))
                : undefined
            }
            onChange={value => {
              action({
                type: 'UPDATE_CATEGORY_RELATED_ATTRIBUTE',
                payload: {
                  relatedAttribute: relatedAttribute && {
                    ...relatedAttribute,
                    selectedValues: value ? [value] : [],
                  },
                },
              });
            }}
            {...(!relatedAttribute && {
              notFoundContent: (
                <Empty
                  className='!my-3'
                  description='ابتدا فیلد مورد نظر را انتخاب کنید.'
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 'calc(var(--ant-control-height-lg) * 0.875)',
                  }}
                />
              ),
            })}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='پیوند یکتا'
          name='subSlug'
          rules={[inputRule.required, inputRule.nonDot]}
        >
          <Input
            placeholder='پیوند یکتا دسته‌بندی را وارد کنید'
            addonAfter={parent && `/${parent.slug}`}
            classNames={{
              input: '[&:not([value=""])]:[direction:ltr]',
            }}
            onChange={({ target: { value } }) => {
              debounceUpdateSubSlug(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType> label='توضیحات' name='description'>
          <RichTextEditor
            config={{
              placeholder: {
                placeholder: 'توضیحات را وارد کنید',
              },
            }}
            upload={{
              ownerType,
              action: uploadAction,
            }}
            options={{
              content: description,
              onUpdate: ({ editor }) => {
                debounceUpdateDescription(editor.getHTML());
              },
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { CategoryFormContent };
