/* eslint-disable react/prop-types */
import * as React from 'react';

import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

import type { FileInfo } from 'types';
import { FILE, validateMediaFile } from 'utils';

const {
  TYPES: { IMAGES },
} = FILE;

type ImgCropUploadProps<T> = Partial<
  Record<
    'imgCropProps',
    Partial<React.ComponentPropsWithoutRef<typeof ImgCrop>>
  >
> &
  React.ComponentPropsWithoutRef<typeof Upload<T>>;

function ImgCropUpload<T = FileInfo>({
  imgCropProps = {
    modalTitle: 'ویرایش عکس',
    aspectSlider: true,
    rotationSlider: true,
    quality: 1,
  },
  showUploadList = false,
  children,
  ...props
}: ImgCropUploadProps<T>) {
  return (
    <ImgCrop {...imgCropProps}>
      <Upload<T>
        showUploadList={showUploadList}
        beforeUpload={({ type }) =>
          validateMediaFile(type, IMAGES) || Upload.LIST_IGNORE
        }
        {...props}
      >
        {children}
      </Upload>
    </ImgCrop>
  );
}

export { ImgCropUpload };
