import type { BaseSortType } from 'types';

const BASE_SORT_TYPE = {
  NEWEST: 'Newest',
  START_TIME: 'StartTime',
  END_TIME: 'EndTime',
} satisfies Record<string, BaseSortType>;

const PATH = {
  DEFAULT: {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    LOCALE: 'fa-IR',
    SLUG_SEPARATOR: '/',
  },
  LOCAL: {
    HOME: {
      BASE_URL: '/',
    },
    PRODUCTS: {
      BASE_URL: '/products',
      CREATE_URL: 'create-product',
      UPDATE_URL: 'update-product',
    },
    ORDERS: {
      BASE_URL: '/orders',
      CREATE_URL: 'create-order',
      UPDATE_URL: 'update-order',
    },
    MARKETING: {
      BASE_URL: '/marketing',
    },
    USERS: {
      BASE_URL: '/users',
      CREATE_URL: 'create-user',
      UPDATE_URL: 'update-user',
    },
    GLOBAL_EDIT: {
      BASE_URL: '/global-edit',
    },
    CATEGORIES: {
      BASE_URL: '/categories',
      CREATE_URL: 'create-category',
      UPDATE_URL: 'update-category',
    },
    BRANDS: {
      BASE_URL: '/brands',
      CREATE_URL: 'create-brand',
      UPDATE_URL: 'update-brand',
    },
    COMMENTS_MANAGEMENT: {
      BASE_URL: '/comments-management',
      PRODUCTS_COMMENTS: {
        BASE_URL: 'products-comments',
        CREATE_URL: 'create-product-comment',
        UPDATE_URL: 'update-product-comment',
      },
      QUESTIONS: {
        BASE_URL: 'questions',
        CREATE_URL: 'create-question',
        UPDATE_URL: 'update-question',
      },
    },
    SEO_SETTING: {
      BASE_URL: '/seo-setting',
    },
    LAYOUT_SETTING: {
      BASE_URL: '/layout-setting',
      MEGA_MENUS: {
        BASE_URL: 'mega-menus',
        CREATE_URL: 'create-mega-menu',
        UPDATE_URL: 'update-mega-menu',
      },
    },
    REPORTS: {
      BASE_URL: '/reports',
    },
    LOGISTIC: {
      BASE_URL: '/logistic',
    },
    ATTRIBUTES: {
      BASE_URL: '/attributes',
      CREATE_URL: 'create-attribute',
      UPDATE_URL: 'update-attribute',
    },
    PACKAGE_MANAGEMENT: {
      BASE_URL: '/package-management',
    },
    PACKAGING_MODELS: {
      BASE_URL: '/packaging-models',
      CREATE_URL: 'create-packaging-model',
      UPDATE_URL: 'update-packaging-model',
    },
    LOGIN: {
      BASE_URL: '/login',
    },
  },
  QUERY_PARAM: {
    DEFAULT: {
      SORT_TYPE: BASE_SORT_TYPE.NEWEST,
      SEPARATOR: '|',
      PAGE_NUMBER: 1,
      PAGE_SIZE: 10,
    },
    SORT_TYPE: {
      BASE_URL: 'sort-type',
    },
    SEARCH_KEY: {
      BASE_URL: 'search-key',
    },
    CATEGORIES_IDS: {
      BASE_URL: 'categories-ids',
    },
    BRANDS_IDS: {
      BASE_URL: 'brands-ids',
    },
    STATUSES: {
      BASE_URL: 'statuses',
    },
    CALL_FOR_PRICE: {
      BASE_URL: 'call-for-price',
    },
    PAGE_SIZE: {
      BASE_URL: 'page-size',
    },
    PAGE_NUMBER: {
      BASE_URL: 'page-number',
    },
    ID: {
      BASE_URL: 'id',
    },
  },
} as const;

export { BASE_SORT_TYPE, PATH };
