import * as React from 'react';

import { Flex, Layout } from 'antd';
import { Helmet } from 'react-helmet-async';

import { KashilandLogo } from 'components';
import { LoginFormContext } from 'context';

import { LoginForm, LoginWallpaper } from './components';

export default function Login() {
  return (
    <>
      <Helmet>
        <title>صفحه ورود | کاشی لند</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <Layout className='h-dvh overflow-hidden'>
        <Layout.Content className='relative'>
          <Flex
            justify='center'
            align='center'
            className='absolute inset-x-0 py-8'
          >
            <KashilandLogo width={190} height={50} />
          </Flex>

          <Flex align='center' justify='center' className='h-full'>
            <LoginFormContext>
              <LoginForm />
            </LoginFormContext>
          </Flex>
        </Layout.Content>

        <Layout.Sider width='60%' className='max-lg:hidden'>
          <LoginWallpaper />
        </Layout.Sider>
      </Layout>
    </>
  );
}
