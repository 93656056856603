import { Fetch } from 'lib';
import type { IdentityPayload } from 'types';

type RequestResponses = {};

type RequestParameters = {};

type RequestBody = Pick<IdentityPayload, 'phoneNumber' | 'email' | 'tokenType'>;

const forgetPassword = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/forgetPassword',
    method: 'POST',
    body,
  });

export { forgetPassword };
