import * as React from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import { Button, Col, Form, Input, Row } from 'antd';

import { ImgCropUpload, RichTextEditor } from 'components';
import { useBrandAction, useBrandValue } from 'context';
import { generateFileUrl, generateRequestUrl, inputRule } from 'utils';

type FieldType = Pick<
  ReturnType<typeof useBrandValue>,
  'title' | 'bannerId' | 'slug' | 'abstraction' | 'description'
>;

const BrandFormContent = () => {
  const {
    title = '',
    slug,
    bannerId,
    abstraction,
    description,
  } = useBrandValue();
  const action = useBrandAction();

  const { run: debounceUpdateTitle } = useDebounceFn(
    (debouncedTitle: typeof title) => {
      action({
        type: 'UPDATE_BRAND_TITLE',
        payload: {
          title: debouncedTitle,
        },
      });
    },
  );
  const { run: debounceUpdateSlug } = useDebounceFn(
    (debouncedSlug: typeof slug) => {
      action({
        type: 'UPDATE_BRAND_SLUG',
        payload: {
          slug: debouncedSlug,
        },
      });
    },
  );
  const { run: debounceUpdateDescription } = useDebounceFn(
    (debouncedDescription: typeof description) => {
      action({
        type: 'UPDATE_BRAND_DESCRIPTION',
        payload: {
          description: debouncedDescription,
        },
      });
    },
  );

  const ownerType = 'Brand';
  const uploadAction = generateRequestUrl('adminBrand/uploadBrandFile');

  return (
    <Row className='rounded bg-white px-6 py-8 lg:basis-[70%]'>
      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='عنوان'
          name='title'
          rules={[inputRule.required, inputRule.string]}
        >
          <Input
            placeholder='عنوان برند را وارد کنید'
            onChange={({ target: { value } }) => {
              debounceUpdateTitle(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='نشان‌واره'
          name='bannerId'
          valuePropName='file'
        >
          <ImgCropUpload
            showUploadList
            listType='picture'
            className='[&>.ant-upload-select]:w-full [&_.ant-upload-list-item-thumbnail]:!h-[inherit] [&_.ant-upload-list-item-thumbnail]:rounded-sm [&_.ant-upload-list-item-thumbnail]:py-0.5 [&_.ant-upload-list-item]:!mt-0  [&_.ant-upload-list-item]:!h-10'
            maxCount={1}
            action={uploadAction}
            fileList={
              bannerId
                ? [
                    {
                      uid: bannerId,
                      name: title,
                      status: 'done',
                      url: generateFileUrl(ownerType, bannerId, 200, 200),
                    },
                  ]
                : undefined
            }
            onChange={({ file: { status, response } }) => {
              if (status === 'done' && response) {
                action({
                  type: 'UPDATE_BRAND_BANNER_ID',
                  payload: {
                    bannerId: response.id,
                  },
                });
              }
              if (status === 'removed') {
                action({
                  type: 'UPDATE_BRAND_BANNER_ID',
                  payload: {
                    bannerId: undefined,
                  },
                });
              }
            }}
          >
            {!bannerId ? (
              <Button
                icon={<UploadOutlined />}
                className='!w-full !justify-start !text-sm !text-[var(--ant-color-text-placeholder)]'
              >
                نشان‌واره برند را بار‌گذاری کنید
              </Button>
            ) : null}
          </ImgCropUpload>
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:pe-3'>
        <Form.Item<FieldType>
          label='پیوند یکتا'
          name='slug'
          rules={[inputRule.required, inputRule.nonDot]}
        >
          <Input
            placeholder='پیوند یکتا برند را وارد کنید'
            onChange={({ target: { value } }) => {
              debounceUpdateSlug(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24} lg={12} className='lg:ps-3'>
        <Form.Item<FieldType>
          label='توضیحات مختصر'
          name='abstraction'
          rules={[inputRule.string, inputRule.abstraction]}
          initialValue={abstraction}
        >
          <Input.TextArea
            rows={1}
            placeholder='توضیحات مختصر برند را وارد کنید'
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType> label='توضیحات' name='description'>
          <RichTextEditor
            config={{
              placeholder: {
                placeholder: 'توضیحات را وارد کنید',
              },
            }}
            upload={{
              ownerType,
              action: uploadAction,
            }}
            options={{
              content: description,
              onUpdate: ({ editor }) => {
                debounceUpdateDescription(editor.getHTML());
              },
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { BrandFormContent };
