/* eslint-disable react/prop-types */
import * as React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { useBoolean, useMemoizedFn } from 'ahooks';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  Row,
  Select,
  Typography,
} from 'antd';

import { CheckInputbox, DocumentViewer, DraggerUpload } from 'components';
import { useProductAction, useProductValue } from 'context';
import type { ProductFileInfo as ProductFileInfoConfig } from 'types';
import {
  FILE,
  generateFileUrl,
  generateRequestUrl,
  generateVariableSubProductTitle,
  inputRule,
  validateMediaFile,
} from 'utils';

const { IMAGES, MOVIES, DOCUMENTS } = FILE.TYPES;

type FieldType = Partial<Record<string, string | string[]>>;

type ProductFileInfoProps = ProductFileInfoConfig & {
  handleDeleteFile: () => void;
};

const ProductFileInfo = React.memo<ProductFileInfoProps>(
  ({ id, title, watermark, handleDeleteFile }) => {
    const { name, productType, subProducts = [] } = useProductValue();

    return (
      <List.Item className='my-5 rounded !border'>
        <List.Item.Meta
          className='!items-center'
          title={
            <Row>
              <Col span={24} lg={4} className='lg:pe-3'>
                <DocumentViewer
                  prefetchMethod='GET'
                  documents={[
                    {
                      uri: generateFileUrl('Product', id, 210, 210),
                    },
                  ]}
                />
              </Col>

              <Col span={24} lg={20} className='lg:ps-3'>
                <Row>
                  <Col span={24} lg={12} className='lg:pe-3'>
                    <Form.Item<FieldType>
                      label='عنوان'
                      name={`file-title-${id}`}
                      rules={[inputRule.string]}
                      initialValue={title}
                    >
                      <Input placeholder='عنوان محصول را وارد کنید' />
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12} className='lg:ps-3'>
                    {/* NOTE: Handle this Form Item with Root Form Fields */}
                    <Form.Item<FieldType>
                      label='مدل'
                      name={`file-subProduct-${id}`}
                    >
                      <Select
                        allowClear
                        placeholder='مدل محصول را انتخاب کنید'
                        options={subProducts.map(({ id, attributes }) => ({
                          label:
                            productType === 'Normal'
                              ? name
                              : productType === 'Variable'
                                ? attributes.map((attribute, index) => {
                                    const { name, value } =
                                      generateVariableSubProductTitle(
                                        attribute,
                                      );

                                    return (
                                      <React.Fragment key={id}>
                                        {index !== 0 ? <span> | </span> : null}
                                        <span>{name}: </span>
                                        <span>{value}</span>
                                      </React.Fragment>
                                    );
                                  })
                                : undefined,
                          value: id,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12} className='lg:pe-3'>
                    <Form.Item<FieldType>
                      label='نهان‌نگاری'
                      name={`file-watermark-${id}`}
                      valuePropName='checked'
                      initialValue={watermark}
                    >
                      <CheckInputbox>
                        محصول نیاز به نمایش نهان‌نگاری دارد
                      </CheckInputbox>
                    </Form.Item>
                  </Col>

                  <Col span={24} lg={12} className='lg:ps-3'>
                    <Form.Item<FieldType> label=' '>
                      <Button
                        danger
                        type='primary'
                        className='w-full'
                        icon={<DeleteOutlined />}
                        onClick={handleDeleteFile}
                      >
                        حذف مستند
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        />
      </List.Item>
    );
  },
);
ProductFileInfo.displayName = 'ProductFileInfo';

const ProductFilesInfo = () => {
  const [picturesLoading, { set: setPicturesLoading }] = useBoolean(false);
  const [documentsLoading, { set: setDocumentsLoading }] = useBoolean(false);
  const { pictures = [], documents = [] } = useProductValue();
  const action = useProductAction();

  const handleDeletePicture = useMemoizedFn((pictureId: string) => {
    const updatedPictures = pictures.filter(
      picture => picture.id !== pictureId,
    );

    action({
      type: 'UPDATE_PRODUCT_PICTURES',
      payload: {
        pictures: updatedPictures,
      },
    });
  });

  const handleDeleteDocument = useMemoizedFn((documentId: string) => {
    const updatedDocuments = documents.filter(
      document => document.id !== documentId,
    );

    action({
      type: 'UPDATE_PRODUCT_DOCUMENTS',
      payload: {
        documents: updatedDocuments,
      },
    });
  });

  return (
    <Row>
      <Col span={24}>
        <Form.Item>
          <DraggerUpload
            action={generateRequestUrl('adminProduct/uploadProductFile')}
            disabled={picturesLoading || documentsLoading}
            onChange={({ file: { type, status, response } }) => {
              if (type) {
                const isPictureFile = validateMediaFile(type, [
                  ...IMAGES,
                  ...MOVIES,
                ]);
                const isDocumentFile = validateMediaFile(type, DOCUMENTS);

                if (isPictureFile) {
                  setPicturesLoading(true);
                }
                if (isDocumentFile) {
                  setDocumentsLoading(true);
                }

                if (status === 'done' && response) {
                  if (isPictureFile) {
                    action({
                      type: 'UPDATE_PRODUCT_PICTURES',
                      payload: {
                        pictures: [...pictures, response],
                      },
                    });
                  }
                  if (isDocumentFile) {
                    action({
                      type: 'UPDATE_PRODUCT_DOCUMENTS',
                      payload: {
                        documents: [...documents, response],
                      },
                    });
                  }
                }

                if (status !== 'uploading') {
                  if (isPictureFile) {
                    setPicturesLoading(false);
                  }
                  if (isDocumentFile) {
                    setDocumentsLoading(false);
                  }
                }
              }
            }}
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Typography.Title level={3} className='!text-base'>
          تصاویر و ویدیو‌های محصول
        </Typography.Title>

        <List
          size='large'
          className='[&_li]:!border-solid [&_li]:!border-[inherit]'
          loading={picturesLoading}
          dataSource={pictures}
          renderItem={item => (
            <ProductFileInfo
              handleDeleteFile={() => {
                handleDeletePicture(item.id);
              }}
              {...item}
            />
          )}
        />
      </Col>

      <Col span={24}>
        <Divider dashed style={{ marginBlockStart: 8, marginBlockEnd: 20 }} />
      </Col>

      <Col span={24}>
        <Typography.Title level={3} className='!text-base'>
          فایل‌های محصول
        </Typography.Title>

        <List
          size='large'
          className='[&_li]:!border-solid [&_li]:!border-[inherit]'
          loading={documentsLoading}
          dataSource={documents}
          renderItem={item => (
            <ProductFileInfo
              handleDeleteFile={() => {
                handleDeleteDocument(item.id);
              }}
              {...item}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export { ProductFileInfo, ProductFilesInfo };
