import { clsx } from 'clsx';
import type { ClassValue as ClassNameValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { v4 as uuId } from 'uuid';
import type { V4Options as UUIdOptions } from 'uuid';

const cn = (...inputs: ClassNameValue[]) => twMerge(clsx(inputs));

const randomId = (options?: UUIdOptions) => uuId(options);

const createTimestamp = (ms: number) => Date.now() + ms;

const padWithZero = (value: string, maxLength: number) =>
  value.padStart(maxLength, '0');

const validateMediaFile = (type: string, validTypes: readonly string[]) =>
  validTypes.some(validType => type.toLowerCase().endsWith(validType));

const formatIdentifier = (value: string, replaceValue = '-') =>
  value.trim().replace(/[\s*]+/gm, replaceValue);

const normalizeToString = (input?: any): string => (input ?? '').toString();

const adjustPageNumber = (
  total: number,
  pageSize: number,
  pageNumber: number,
  updatePageNumber: (pageNumber: number) => void,
) => {
  const totalPage = Math.ceil(total / pageSize);
  if (pageNumber > totalPage && totalPage >= 1) {
    updatePageNumber(totalPage);
  }
};

export {
  adjustPageNumber,
  cn,
  createTimestamp,
  formatIdentifier,
  normalizeToString,
  padWithZero,
  randomId,
  validateMediaFile,
};
