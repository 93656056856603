import * as React from 'react';

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type DndSortableContextProps = {
  dnd: React.ComponentPropsWithoutRef<typeof DndContext>;
  sortable: React.ComponentPropsWithoutRef<typeof SortableContext>;
};

const DndSortableContext = ({
  dnd: {
    sensors: modifiedSensors,
    collisionDetection = closestCenter,
    modifiers = [
      restrictToVerticalAxis,
      restrictToWindowEdges,
      restrictToFirstScrollableAncestor,
    ],
    ...otherDndProps
  },
  sortable: {
    items = [],
    strategy = verticalListSortingStrategy,
    children,
    ...otherSortableProps
  },
}: DndSortableContextProps) => {
  const defaultSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const sensors = modifiedSensors ?? defaultSensors;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      modifiers={modifiers}
      {...otherDndProps}
    >
      <SortableContext
        items={items}
        strategy={strategy}
        {...otherSortableProps}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};

export {
  CSS as DND_CSS,
  arrayMove as dndArrayMove,
  DndSortableContext,
  useSortable as useDndSortable,
};
