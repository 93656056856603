import { Fetch } from 'lib';
import type { User } from 'types';

type RequestResponses = User;

type RequestParameters = {
  id: string;
};

const getUser = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminUser/getUser',
    params,
  });

export { getUser };
