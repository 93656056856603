import * as React from 'react';

import { MenuOutlined } from '@ant-design/icons';
import {
  useBoolean,
  useGetState,
  useLockFn,
  useMemoizedFn,
  useRequest,
  useUpdateEffect,
} from 'ahooks';
import {
  Button,
  Divider,
  List,
  Modal,
  notification,
  Space,
  TreeSelect,
  Typography,
} from 'antd';

import { DND_CSS, dndArrayMove, DndSortableContext, useDndSortable } from 'lib';
import { getCategories, getCategory, updateOrderOfAttributes } from 'services';
import type { VariableAttribute } from 'types';
import { generateTreeSelectData, normalizeToString } from 'utils';

type AttributesOrderListItemProps = VariableAttribute;

const AttributesOrderListItem = ({
  id,
  name,
}: AttributesOrderListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useDndSortable({
    id,
  });

  return (
    <List.Item
      ref={setNodeRef}
      className='bg-slate-50'
      style={{
        transform: DND_CSS.Transform.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
      }}
      {...attributes}
      {...listeners}
    >
      <List.Item.Meta avatar={<MenuOutlined />} title={name} />
    </List.Item>
  );
};

type AttributesOrderListProps = {
  selectedAttributes: VariableAttribute[];
  selectedCategory: string | undefined;
  handleSelectedAttributes: (attributes: VariableAttribute[]) => void;
};

const AttributesOrderList = React.memo<AttributesOrderListProps>(
  ({
    selectedAttributes,
    selectedCategory,
    handleSelectedAttributes,
  }: AttributesOrderListProps) => {
    const { loading, runAsync } = useRequest(getCategory, {
      manual: true,
    });

    useUpdateEffect(() => {
      if (selectedCategory) {
        runAsync({ id: selectedCategory }).then(({ attributes }) => {
          handleSelectedAttributes(attributes);
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory]);

    return (
      <DndSortableContext
        dnd={{
          onDragEnd: ({ active, over }) => {
            const activeId = active.id;
            const overId = over?.id;

            if (activeId !== overId) {
              const activeIndex = selectedAttributes.findIndex(
                ({ id }) => id === activeId,
              );
              const overIndex = selectedAttributes.findIndex(
                ({ id }) => id === overId,
              );
              const sortedSelectedAttributes = dndArrayMove(
                selectedAttributes,
                activeIndex,
                overIndex,
              );

              handleSelectedAttributes(sortedSelectedAttributes);
            }
          },
        }}
        sortable={{
          items: selectedAttributes.map(({ id }) => id),
          children: (
            <List
              bordered
              size='large'
              className='max-h-[calc(100dvh-25rem)] overflow-y-auto'
              loading={loading}
              dataSource={selectedAttributes}
              renderItem={item => <AttributesOrderListItem {...item} />}
            />
          ),
        }}
      />
    );
  },
);
AttributesOrderList.displayName = 'AttributesOrderList';

type AttributesOrderSelectProps = {
  handleSelectedCategory: (id?: string) => void;
};

const AttributesOrderSelect = React.memo<AttributesOrderSelectProps>(
  ({ handleSelectedCategory }: AttributesOrderSelectProps) => {
    const { data, loading } = useRequest(getCategories, {
      cacheKey: 'categories',
    });

    const totalCategories = data?.categories ?? [];
    const categoriesTreeData = generateTreeSelectData(
      totalCategories,
      'id',
      'title',
      'id',
    );

    return (
      <TreeSelect<string>
        showSearch
        className='w-80'
        placeholder='گزینه ای را انتخاب کنید'
        loading={loading}
        treeData={categoriesTreeData}
        filterTreeNode={(inputValue, treeNode) =>
          normalizeToString(treeNode.label)
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        }
        onChange={value => {
          handleSelectedCategory(value);
        }}
      />
    );
  },
);
AttributesOrderSelect.displayName = 'AttributesOrderSelect';

const AttributesOrder = React.memo(() => {
  const [open, { set: setOpen }] = useBoolean(false);
  const [confirmLoading, { set: setConfirmLoading }] = useBoolean(false);
  const [selectedAttributes, setSelectedAttributes] = useGetState<
    VariableAttribute[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useGetState<string>();

  const handleSelectedAttributes = useMemoizedFn(
    (attributes: typeof selectedAttributes) => {
      setSelectedAttributes(attributes);
    },
  );

  const handleSelectedCategory = useMemoizedFn(
    (id: typeof selectedCategory) => {
      setSelectedCategory(id);
    },
  );

  const handleOk = useLockFn(async () => {
    if (selectedCategory && selectedAttributes.length > 0) {
      setConfirmLoading(true);

      updateOrderOfAttributes(
        { categoryId: selectedCategory },
        { attributesIds: selectedAttributes.map(({ id }) => id) },
      )
        .catch(message => {
          notification.error({ message });
        })
        .finally(() => {
          setOpen(false);
          setConfirmLoading(false);
        });
    }
  });

  return (
    <>
      <Button className='max-lg:basis-full' onClick={() => setOpen(true)}>
        ترتیب فیلد‌ها
      </Button>

      <Modal
        centered
        width={600}
        title={
          <Typography.Title level={5} className='!font-medium'>
            ترتیب فیلد‌ها
          </Typography.Title>
        }
        okText='ذخیره تغییرات'
        okButtonProps={{
          className: '!px-5',
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        classNames={{
          content: '!p-0',
          header: '!px-5 !pt-4',
          footer: '!px-5 !pb-3',
        }}
        open={open}
        confirmLoading={confirmLoading}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
      >
        <Divider style={{ marginBlockStart: 15 }} />

        <div className='space-y-4 px-6'>
          <Space>
            <Typography.Text>دسته‌بندی</Typography.Text>
            <AttributesOrderSelect
              handleSelectedCategory={handleSelectedCategory}
            />
          </Space>

          <AttributesOrderList
            selectedAttributes={selectedAttributes}
            selectedCategory={selectedCategory}
            handleSelectedAttributes={handleSelectedAttributes}
          />
        </div>

        <Divider style={{ marginBlockEnd: 6 }} />
      </Modal>
    </>
  );
});
AttributesOrder.displayName = 'AttributesOrder';

export {
  AttributesOrder,
  AttributesOrderList,
  AttributesOrderListItem,
  AttributesOrderSelect,
};
