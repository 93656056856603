import * as React from 'react';

import { MobileOutlined } from '@ant-design/icons';
import { useLockFn, useSafeState } from 'ahooks';
import { Button, Form, Input, notification, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLoginValue } from 'context';
import { signinWithPassword } from 'services';
import { inputRule, PATH } from 'utils';

const {
  LOCAL: { HOME },
} = PATH;

type FieldType = Pick<
  ReturnType<typeof useLoginValue>,
  'phoneNumber' | 'password'
>;

const LoginDefaultForm = () => {
  const [message, setMessage] = useSafeState<string>();
  const [, setSearchParams] = useSearchParams();
  const [form] = Form.useForm<FieldType>();
  const navigate = useNavigate();

  const onFinish = useLockFn(
    async ({ phoneNumber = '', password = '' }: FieldType) => {
      signinWithPassword({ phoneNumber, password })
        .then(() => {
          notification.success({
            message: 'با موفقیت وارد شدید.',
          });
          navigate(HOME.BASE_URL);
        })
        .catch(message => {
          setMessage(message);
        });
    },
  );

  return (
    <div className='basis-1/2 space-y-8'>
      <Typography.Title level={2} className='!text-xl !font-bold'>
        ورود به کاشی لند
      </Typography.Title>

      <Form
        form={form}
        layout='vertical'
        requiredMark={false}
        autoComplete='off'
        onFinish={onFinish}
      >
        <Form.Item<FieldType>
          label='شماره موبایل'
          name='phoneNumber'
          rules={[inputRule.required, inputRule.phoneNumber]}
        >
          <Input
            placeholder='برای مثال ۰۹۱۲۳۴۵۶۷۸۹'
            prefix={
              <MobileOutlined
                className='text-lg'
                style={{ color: 'var(--ant-color-text-placeholder)' }}
              />
            }
          />
        </Form.Item>

        <Form.Item<FieldType>
          label='رمز عبور'
          name='password'
          rules={[
            inputRule.required,
            inputRule.nonWhitespace,
            inputRule.password,
          ]}
        >
          <Input.Password
            placeholder='برای مثال ۱۲۳۴۵۶۷۸۹'
            prefix={
              <MobileOutlined
                className='text-lg'
                style={{ color: 'var(--ant-color-text-placeholder)' }}
              />
            }
          />
        </Form.Item>

        <Typography.Paragraph
          type='danger'
          className='!mb-2 -mt-4 px-2 text-center empty:my-12'
        >
          {message}
        </Typography.Paragraph>

        <Form.Item className='!mb-4'>
          <Button type='primary' htmlType='submit' block>
            ورود
          </Button>
        </Form.Item>

        <Form.Item className='!mb-0'>
          <Button
            block
            onClick={() => setSearchParams('?type=otp', { replace: true })}
          >
            ورود با رمز یک‌بار مصرف
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export { LoginDefaultForm };
