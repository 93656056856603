import * as React from 'react';

import { CategoryActionContext, CategoryValueContext } from './context';
import { useCategoryAction, useCategoryValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const CategoryFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <CategoryValueContext.Provider value={state}>
      <CategoryActionContext.Provider value={dispatch}>
        {children}
      </CategoryActionContext.Provider>
    </CategoryValueContext.Provider>
  );
};

export { CategoryFormContext, useCategoryAction, useCategoryValue };
