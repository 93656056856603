import { useContext } from 'react';

import {
  ProductCommentActionContext,
  ProductCommentValueContext,
} from './context';

const useProductCommentValue = () => {
  const context = useContext(ProductCommentValueContext);

  if (!context) {
    throw new Error(
      'useProductCommentValue must be used within a <ProductCommentFormContext />',
    );
  }

  return context;
};

const useProductCommentAction = () => {
  const context = useContext(ProductCommentActionContext);

  if (!context) {
    throw new Error(
      'useProductCommentAction must be used within a <ProductCommentFormContext />',
    );
  }

  return context;
};

export { useProductCommentAction, useProductCommentValue };
