import * as React from 'react';

import { createBrowserRouter, Navigate } from 'react-router-dom';

import { PrivateLayout } from 'layouts';
import {
  Attribute,
  Attributes,
  Brand,
  Brands,
  Categories,
  Category,
  Home,
  Login,
  NotFound,
  PackagingModel,
  PackagingModels,
  Product,
  ProductComment,
  Products,
  ProductsComments,
} from 'pages';
import {
  checkAuth,
  getAttributeConfig,
  getBrand,
  getCategory,
  getPackagingModel,
  getProduct,
  getProductComment,
} from 'services';
import { PATH } from 'utils';

const {
  LOCAL: {
    HOME,
    PRODUCTS,
    CATEGORIES,
    BRANDS,
    COMMENTS_MANAGEMENT,
    ATTRIBUTES,
    PACKAGING_MODELS,
    LOGIN,
  },
} = PATH;

const router = createBrowserRouter([
  // NOTE: If the user was authenticated, then user can access the private layout routes, otherwise user will be redirected to the login page.
  {
    path: HOME.BASE_URL,
    loader: async () => checkAuth(),
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: PRODUCTS.BASE_URL,
        children: [
          {
            index: true,
            element: <Products />,
          },
          {
            path: PRODUCTS.CREATE_URL,
            element: <Product mode='Create' />,
          },
          {
            path: `${PRODUCTS.UPDATE_URL}/:id`,
            loader: async ({ params }) =>
              await getProduct({
                id: params.id as string,
              }),
            element: <Product mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: CATEGORIES.BASE_URL,
        children: [
          {
            index: true,
            element: <Categories />,
          },
          {
            path: CATEGORIES.CREATE_URL,
            element: <Category mode='Create' />,
          },
          {
            path: `${CATEGORIES.UPDATE_URL}/:id`,
            loader: async ({ params }) =>
              await getCategory({
                id: params.id as string,
              }),
            element: <Category mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: BRANDS.BASE_URL,
        children: [
          {
            index: true,
            element: <Brands />,
          },
          {
            path: BRANDS.CREATE_URL,
            element: <Brand mode='Create' />,
          },
          {
            path: `${BRANDS.UPDATE_URL}/:id`,
            loader: async ({ params }) =>
              await getBrand({
                id: params.id as string,
              }),
            element: <Brand mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: COMMENTS_MANAGEMENT.BASE_URL,
        children: [
          {
            path: COMMENTS_MANAGEMENT.PRODUCTS_COMMENTS.BASE_URL,
            children: [
              {
                index: true,
                element: <ProductsComments />,
              },
              {
                path: COMMENTS_MANAGEMENT.PRODUCTS_COMMENTS.CREATE_URL,
                element: <ProductComment mode='Create' />,
              },
              {
                path: `${COMMENTS_MANAGEMENT.PRODUCTS_COMMENTS.UPDATE_URL}/:id`,
                loader: async ({ params }) =>
                  await getProductComment({
                    id: params.id as string,
                  }),
                element: <ProductComment mode='Update' />,
                errorElement: <NotFound />,
              },
            ],
          },
        ],
      },
      {
        path: ATTRIBUTES.BASE_URL,
        children: [
          {
            index: true,
            element: <Attributes />,
          },
          {
            path: ATTRIBUTES.CREATE_URL,
            element: <Attribute mode='Create' />,
          },
          {
            path: `${ATTRIBUTES.UPDATE_URL}/:id`,
            loader: async ({ params }) =>
              await getAttributeConfig({
                id: params.id as string,
              }),
            element: <Attribute mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: PACKAGING_MODELS.BASE_URL,
        children: [
          {
            index: true,
            element: <PackagingModels />,
          },
          {
            path: PACKAGING_MODELS.CREATE_URL,
            element: <PackagingModel mode='Create' />,
          },
          {
            path: `${PACKAGING_MODELS.UPDATE_URL}/:id`,
            loader: async ({ params }) =>
              await getPackagingModel({
                id: params.id as string,
              }),
            element: <PackagingModel mode='Update' />,
            errorElement: <NotFound />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
    errorElement: <Navigate to={LOGIN.BASE_URL} />,
  },
  // NOTE: If the user is not authenticated, the login page will be displayed to user, otherwise user will be redirected to the main page
  {
    path: LOGIN.BASE_URL,
    loader: async () => checkAuth(),
    element: <Navigate to={HOME.BASE_URL} />,
    errorElement: <Login />,
  },
]);

export { router };
