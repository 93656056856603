import * as React from 'react';

import { Result } from 'antd';
import { Helmet } from 'react-helmet-async';

import { AntdRouterLink } from 'components';
import { PATH } from 'utils';

const {
  LOCAL: { HOME },
} = PATH;

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>خطای 404 | کاشی لند</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <div className='flex h-full items-center justify-center rounded bg-white'>
        <Result
          status='404'
          title='404'
          subTitle='با عرض معذرت این صفحه دردسترس نیست.'
          extra={
            <AntdRouterLink to={HOME.BASE_URL}>بازگشت به خانه</AntdRouterLink>
          }
        />
      </div>
    </>
  );
}
