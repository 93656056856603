import * as React from 'react';

import { Typography } from 'antd';

const ProductHeader = ({ children }: React.PropsWithChildren<unknown>) => (
  <Typography.Title level={2} className='!mb-6 !text-base'>
    {children}
  </Typography.Title>
);

export { ProductHeader };
