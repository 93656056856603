import * as React from 'react';

import { InputNumber } from 'antd';

import { cn } from 'utils';

type DecimalInputNumberProps = Omit<
  React.ComponentPropsWithoutRef<typeof InputNumber>,
  'formatter' | 'parser'
>;

const DecimalInputNumber = React.forwardRef<
  React.ElementRef<typeof InputNumber>,
  DecimalInputNumberProps
>(({ className, ...props }, ref) => (
  <InputNumber
    ref={ref}
    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
    className={cn('!w-full', className)}
    {...props}
  />
));
DecimalInputNumber.displayName = 'DecimalInputNumber';

export { DecimalInputNumber };
