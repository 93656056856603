import * as React from 'react';

import { Flex, Input, Typography } from 'antd';

type ProductsCommentsHeaderProps = {
  total: number;
  searchKey: string;
  handleSearchKey: (searchKey: string) => void;
};

const ProductsCommentsHeader = ({
  total,
  searchKey,
  handleSearchKey,
}: ProductsCommentsHeaderProps) => (
  <Flex
    gap={10}
    className='mb-5 max-lg:flex-col lg:items-center lg:justify-between'
  >
    <Typography.Title level={2} className='py-1' style={{ marginBlockEnd: 0 }}>
      <span className='text-base'>دیدگاه کاربران</span>
      <span className='text-sm text-slate-400'> ({total})</span>
    </Typography.Title>

    <Flex gap={15} className='max-lg:flex-wrap'>
      <Input.Search
        allowClear
        styles={{
          affixWrapper: {
            height: '2.5rem',
          },
        }}
        placeholder='جستجو دیدگاه کاربران'
        defaultValue={searchKey}
        onSearch={value => handleSearchKey(value)}
      />
    </Flex>
  </Flex>
);

export { ProductsCommentsHeader };
