import { Fetch } from 'lib';
import type { AttributeConfigSummary } from 'types';

type RequestResponses = {
  attributes: AttributeConfigSummary[];
};

type RequestParameters = {
  categoryId: string;
};

type RequestBody = {
  attributesIds: string[];
};

const updateOrderOfAttributes = async (
  params: RequestParameters,
  body: RequestBody,
) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminAttributeConfig/updateOrderOfAttributes',
    method: 'PATCH',
    params,
    body,
  });

export { updateOrderOfAttributes };
