import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { ProductCommentFormContext } from 'context';
import type {
  ComponentMode,
  ProductComment as ProductCommentConfig,
} from 'types';

import { ProductCommentForm, ProductCommentHeader } from './components';

type ProductCommentProps = {
  mode: ComponentMode;
};

export default function ProductComment({ mode }: ProductCommentProps) {
  const productCommentData = useLoaderData() as ProductCommentConfig;

  const isUpdatedProductComment = mode === 'Update';
  const defaultName = isUpdatedProductComment
    ? `دیدگاه "${productCommentData.user.firstName} ${productCommentData.user.lastName}" برای "${productCommentData.product.name}"`
    : '';
  const defaultStatus = isUpdatedProductComment
    ? productCommentData.status
    : 'Accepted';
  const productCommentTitle = isUpdatedProductComment
    ? defaultName
    : 'افزودن دیدگاه جدید';

  return (
    <>
      <Helmet>
        <title>{`صفحه ${productCommentTitle} | کاشی لند`}</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <ProductCommentHeader>{productCommentTitle}</ProductCommentHeader>

      <ProductCommentFormContext
        mode={mode}
        defaultName={defaultName}
        defaultStatus={defaultStatus}
        {...(isUpdatedProductComment && productCommentData)}
      >
        <ProductCommentForm />
      </ProductCommentFormContext>
    </>
  );
}
