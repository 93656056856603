import * as React from 'react';

import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useUpdateEffect } from 'ahooks';
import {
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Modal,
  notification,
  Space,
  Table,
  Tag,
} from 'antd';

import { AntdRouterLink } from 'components';
import { useMediaQuery } from 'hooks';
import { deleteAttributeConfig } from 'services';
import type { AttributeConfigSummary } from 'types';
import {
  adjustPageNumber,
  generateTableDataSource,
  localizeAttributeType,
  PATH,
} from 'utils';

const {
  LOCAL: { ATTRIBUTES },
} = PATH;

type AttributesTableProps = {
  loading: boolean;
  total: number;
  attributes: AttributeConfigSummary[];
  pageSize: number;
  pageNumber: number;
  handlePageSize: (pageSize: number) => void;
  handlePageNumber: (pageNumber: number) => void;
  handleRefreshData: () => void;
};

const AttributesTable = React.memo<AttributesTableProps>(
  ({
    loading,
    total,
    attributes,
    pageSize,
    pageNumber,
    handlePageSize,
    handlePageNumber,
    handleRefreshData,
  }: AttributesTableProps) => {
    const [modal, contextHolder] = Modal.useModal();
    const responsive = useMediaQuery();

    useUpdateEffect(() => {
      adjustPageNumber(total, pageSize, pageNumber, handlePageNumber);
    }, [total, pageSize, pageNumber]);

    const handleDeleteAttributeConfig = async (id: string) => {
      deleteAttributeConfig({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const attributesDataSource = generateTableDataSource(attributes, 'id');

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: !responsive.lg || undefined, y: 630 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'name',
            key: 'name',
            width: '23%',
            ellipsis: true,
            render: (_, { id, name }) => (
              <AntdRouterLink to={`${ATTRIBUTES.UPDATE_URL}/${id}`}>
                {name}
              </AntdRouterLink>
            ),
          },
          {
            title: 'دسته‌بندی‌های اختصاص یافته',
            dataIndex: 'categories',
            key: 'categories',
            ellipsis: true,
            render: (_, { categories }) => (
              <Space className='flex-wrap'>
                {categories.map(({ id, title }) => (
                  <Tag key={id}>{title}</Tag>
                ))}
              </Space>
            ),
          },
          {
            title: 'نوع',
            dataIndex: 'type',
            key: 'type',
            width: 125,
            ellipsis: true,
            render: (_, { type }) => localizeAttributeType(type).label,
          },
          {
            title: 'قابلیت فیلتر',
            dataIndex: 'isFilter',
            key: 'isFilter',
            width: 125,
            ellipsis: true,
            render: (_, { isFilter }) => (
              <Flex justify='center'>
                <Checkbox defaultChecked={isFilter} disabled />
              </Flex>
            ),
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, name }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-attribute-config',
                        label: (
                          <AntdRouterLink
                            to={`${ATTRIBUTES.UPDATE_URL}/${id}`}
                            className='inline-block py-1 !text-inherit'
                          >
                            <EditOutlined />
                            <span className='ms-2 text-sm'>ویرایش فیلد</span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-attribute-config',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='!flex items-center'
                              style={{ paddingInline: 0, marginBlock: 2 }}
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف فیلد "${name}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteAttributeConfig(id);
                                }
                              }}
                            >
                              <DeleteOutlined />
                              <span className='text-sm'>حذف فیلد</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Button size='small' className='!py-3.5'>
                    <MoreOutlined style={{ fontSize: 20 }} />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={attributesDataSource}
        pagination={{
          position: ['bottomRight'],
          className: '[&_.ant-select]:!h-8',
          responsive: true,
          showQuickJumper: true,
          showTotal: () => `در مجموع ${total} ردیف`,
          total,
          pageSize,
          current: pageNumber,
          onChange: (page, size) => {
            handlePageSize(size);

            if (page !== pageNumber) {
              handlePageNumber(page);
            }
          },
        }}
      />
    );
  },
);
AttributesTable.displayName = 'AttributesTable';

export { AttributesTable };
