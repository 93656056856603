import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { AttributeFormContext } from 'context';
import type { AttributeConfig, ComponentMode } from 'types';

import { AttributeForm, AttributeHeader } from './components';

type AttributeProps = {
  mode: ComponentMode;
};

export default function Attribute({ mode }: AttributeProps) {
  const attributeData = useLoaderData() as AttributeConfig;

  const isUpdatedAttribute = mode === 'Update';
  const attributeTitle = isUpdatedAttribute
    ? attributeData.name
    : 'افزودن فیلد جدید';
  const defaultName = isUpdatedAttribute ? attributeData.name : '';

  return (
    <>
      <Helmet>
        <title>{`صفحه ${attributeTitle} | کاشی لند`}</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <AttributeHeader>{attributeTitle}</AttributeHeader>

      <AttributeFormContext
        mode={mode}
        defaultName={defaultName}
        {...(isUpdatedAttribute && attributeData)}
      >
        <AttributeForm />
      </AttributeFormContext>
    </>
  );
}
