import { Fetch } from 'lib';
import type { Category } from 'types';

type RequestResponses = Category;

type RequestParameters = {
  id: string;
};

const getCategory = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminCategory/getCategory',
    params,
  });

export { getCategory };
