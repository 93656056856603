import { Fetch } from 'lib';

type RequestResponses = {};

type RequestParameters = {
  id: string;
};

type RequestBody = {
  newPassword: string;
};

const changeUserPassword = async (
  params: RequestParameters,
  body: RequestBody,
) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminUser/changeUserPassword',
    method: 'PUT',
    params,
    body,
  });

export { changeUserPassword };
