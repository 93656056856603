import { PATH } from 'utils';

const {
  DEFAULT: { LOCALE: DEFAULT_LOCALE },
} = PATH;

const decimalFormat = (
  value: number,
  addonAfter = '',
  locales: string | string[] = DEFAULT_LOCALE,
) =>
  new Intl.NumberFormat(locales, {
    style: 'decimal',
  })
    .format(value)
    .concat(addonAfter === '' ? addonAfter : ` ${addonAfter}`);

const dateFormat = (
  date: number | Date,
  locales: string | string[] = DEFAULT_LOCALE,
) =>
  new Intl.DateTimeFormat(locales, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date);

export { dateFormat, decimalFormat };
