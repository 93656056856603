import * as React from 'react';

import { LoginActionContext, LoginValueContext } from './context';
import { useLoginAction, useLoginValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const LoginFormContext = ({ children }: React.PropsWithChildren<unknown>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <LoginValueContext.Provider value={state}>
      <LoginActionContext.Provider value={dispatch}>
        {children}
      </LoginActionContext.Provider>
    </LoginValueContext.Provider>
  );
};

export { LoginFormContext, useLoginAction, useLoginValue };
