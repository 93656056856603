import { Fetch } from 'lib';
import { createBrand } from 'services';

type RequestResponses = Awaited<ReturnType<typeof createBrand>>;

type RequestParameters = {
  id: string;
};

type RequestBody = Parameters<typeof createBrand>[number];

const updateBrand = async (params: RequestParameters, body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminBrand/updateBrand',
    method: 'PUT',
    params,
    body,
  });

export { updateBrand };
