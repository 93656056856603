import { Fetch } from 'lib';
import { createCategory } from 'services';

type RequestResponses = Awaited<ReturnType<typeof createCategory>>;

type RequestParameters = {
  id: string;
};

type RequestBody = Parameters<typeof createCategory>[number];

const updateCategory = async (params: RequestParameters, body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminCategory/updateCategory',
    method: 'PUT',
    params,
    body,
  });

export { updateCategory };
