import * as React from 'react';

import {
  PackagingModelActionContext,
  PackagingModelValueContext,
} from './context';
import { usePackagingModelAction, usePackagingModelValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const PackagingModelFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <PackagingModelValueContext.Provider value={state}>
      <PackagingModelActionContext.Provider value={dispatch}>
        {children}
      </PackagingModelActionContext.Provider>
    </PackagingModelValueContext.Provider>
  );
};

export {
  PackagingModelFormContext,
  usePackagingModelAction,
  usePackagingModelValue,
};
