import { Fetch } from 'lib';
import type { Brand } from 'types';

type RequestResponses = Brand;

type RequestParameters = {};

type RequestBody = Omit<Brand, 'id'>;

const createBrand = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminBrand/createBrand',
    method: 'POST',
    body,
  });

export { createBrand };
