import { Fetch } from 'lib';
import type { CategoryQueryResult } from 'types';

type RequestResponses = {
  categories: CategoryQueryResult[];
};

const getCategories = async () =>
  await Fetch<RequestResponses>({
    url: 'adminCategory/getCategories',
  });

export { getCategories };
