import * as React from 'react';

import {
  ProductCommentActionContext,
  ProductCommentValueContext,
} from './context';
import { useProductCommentAction, useProductCommentValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const ProductCommentFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <ProductCommentValueContext.Provider value={state}>
      <ProductCommentActionContext.Provider value={dispatch}>
        {children}
      </ProductCommentActionContext.Provider>
    </ProductCommentValueContext.Provider>
  );
};

export {
  ProductCommentFormContext,
  useProductCommentAction,
  useProductCommentValue,
};
