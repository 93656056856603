import { Fetch } from 'lib';
import type { User } from 'types';

type RequestResponses = User;

type RequestParameters = {};

type RequestBody = Omit<User, 'id' | 'roleName'>;

const createUser = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminUser/createUser',
    method: 'POST',
    body,
  });

export { createUser };
