import { Fetch } from 'lib';
import type { User } from 'types';

type RequestResponses = {};

type RequestParameters = {
  id: string;
};

type RequestBody = Pick<User, 'roleName'>;

const changeUserRole = async (params: RequestParameters, body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminUser/changeUserRole',
    method: 'PUT',
    params,
    body,
  });

export { changeUserRole };
