import * as React from 'react';

import { useBoolean } from 'ahooks';
import { Layout, theme } from 'antd';
import { Outlet } from 'react-router-dom';

import { useMediaQuery } from 'hooks';

import { PrivateHeader } from './header';
import { PrivateSidebar } from './sidebar';

const PrivateLayout = () => {
  const [defaultCollapsed, { toggle }] = useBoolean(false);
  const { token } = theme.useToken();
  const responsive = useMediaQuery();

  const collapsed = responsive.lg ? defaultCollapsed : true;

  return (
    <Layout>
      <Layout.Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        className='h-dvh overflow-y-auto'
        width='15%'
      >
        <PrivateSidebar collapsed={collapsed} />
      </Layout.Sider>

      <Layout className='min-h-dvh'>
        <Layout.Header
          style={{
            paddingInline: '15px 25px',
            background: 'unset',
          }}
        >
          <PrivateHeader collapsed={collapsed} handleCollapse={toggle} />
        </Layout.Header>

        <Layout.Content
          style={{
            padding: '25px',
            backgroundColor: token.colorPrimaryBg,
          }}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export { PrivateLayout };
