import * as React from 'react';

import { AttributeActionContext, AttributeValueContext } from './context';
import { useAttributeAction, useAttributeValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const AttributeFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <AttributeValueContext.Provider value={state}>
      <AttributeActionContext.Provider value={dispatch}>
        {children}
      </AttributeActionContext.Provider>
    </AttributeValueContext.Provider>
  );
};

export { AttributeFormContext, useAttributeAction, useAttributeValue };
