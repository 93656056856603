import * as React from 'react';

import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Flex,
  Modal,
  notification,
  Table,
  TreeSelect,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { AntdRouterLink } from 'components';
import { useMediaQuery } from 'hooks';
import { deleteCategory } from 'services';
import type { CategoryQueryResult } from 'types';
import {
  generateTableDataSource,
  generateTreeSelectData,
  normalizeToString,
  PATH,
} from 'utils';

const {
  LOCAL: { CATEGORIES },
} = PATH;

type CategoriesTableProps = {
  loading: boolean;
  categories: CategoryQueryResult[];
  handleRefreshData: () => void;
};

const CategoriesTable = React.memo<CategoriesTableProps>(
  ({ loading, categories, handleRefreshData }: CategoriesTableProps) => {
    const [modal, contextHolder] = Modal.useModal();
    const responsive = useMediaQuery();
    const navigate = useNavigate();

    const handleDeleteCategory = async (id: string) => {
      deleteCategory({ id })
        .then(() => {
          handleRefreshData();
        })
        .catch(message => {
          notification.error({ message });
        });
    };

    const categoriesDataSource = generateTableDataSource(categories, 'id');
    const categoriesTreeData = generateTreeSelectData(
      categories,
      'id',
      'title',
      'id',
    );

    return (
      <Table
        tableLayout='fixed'
        scroll={{ x: !responsive.lg || undefined, y: 700 }}
        columns={[
          {
            title: 'نام',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
            ellipsis: true,
            filterDropdown: () => (
              <div
                style={{ background: 'var(--ant-table-header-bg)', padding: 8 }}
              >
                <TreeSelect<string>
                  showSearch
                  className='w-80'
                  placeholder='دسته‌بندی مورد نظر را انتخاب کنید'
                  loading={loading}
                  treeData={categoriesTreeData}
                  filterTreeNode={(inputValue, treeNode) =>
                    normalizeToString(treeNode.label)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  onChange={value => {
                    navigate(`${CATEGORIES.UPDATE_URL}/${value}`);
                  }}
                />
              </div>
            ),
            render: (_, { id, title, relatedAttribute }) => (
              <AntdRouterLink to={`${CATEGORIES.UPDATE_URL}/${id}`}>
                <span>{title}</span>

                {relatedAttribute ? (
                  <CheckCircleOutlined className='mx-1 text-green-500' />
                ) : null}
              </AntdRouterLink>
            ),
          },
          {
            title: 'پیوند یکتا',
            dataIndex: 'slug',
            key: 'slug',
            ellipsis: true,
            render: (_, { slug }) => <bdi>{slug}</bdi>,
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            fixed: 'right',
            render: (_, { id, title }) => (
              <Flex align='center' justify='center'>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'update-category',
                        label: (
                          <AntdRouterLink
                            to={`${CATEGORIES.UPDATE_URL}/${id}`}
                            className='inline-block py-1 !text-inherit'
                          >
                            <EditOutlined />
                            <span className='ms-2 text-sm'>
                              ویرایش دسته‌بندی‌
                            </span>
                          </AntdRouterLink>
                        ),
                      },
                      {
                        key: 'delete-category',
                        label: (
                          <>
                            <Button
                              danger
                              type='link'
                              size='small'
                              className='!flex items-center'
                              style={{ paddingInline: 0, marginBlock: 2 }}
                              onClick={async () => {
                                const confirmed = await modal.confirm({
                                  title: `آیا از حذف دسته‌بندی‌ "${title}" اطمینان دارید؟`,
                                  width: 500,
                                });

                                if (confirmed) {
                                  handleDeleteCategory(id);
                                }
                              }}
                            >
                              <DeleteOutlined />
                              <span className='text-sm'>حذف دسته‌بندی‌</span>
                            </Button>

                            {contextHolder}
                          </>
                        ),
                      },
                    ],
                  }}
                  trigger={['click']}
                >
                  <Button size='small' className='!py-3.5'>
                    <MoreOutlined style={{ fontSize: 20 }} />
                    <span className='sr-only'>آیکون اکشن‌ها</span>
                  </Button>
                </Dropdown>
              </Flex>
            ),
          },
        ]}
        loading={loading}
        dataSource={categoriesDataSource}
        pagination={false}
      />
    );
  },
);
CategoriesTable.displayName = 'CategoriesTable';

export { CategoriesTable };
