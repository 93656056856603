import { initialAction, initialState } from './initial';

type Reducer = (
  state: typeof initialState,
  action: typeof initialAction,
) => typeof initialState;

const reducer: Reducer = (state, action) => {
  switch (action?.type) {
    case 'UPDATE_PRODUCT_NAME': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_IDENTIFIER': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_SLUG': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_BRAND': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_MAIN_CATEGORY': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_OTHER_CATEGORIES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_DESCRIPTION': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_BASE_INFO': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_TYPE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_VARIABLE_ATTRIBUTES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_SUB_PRODUCTS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_BASE_ATTRIBUTES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_PICTURES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_DOCUMENTS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_PACKAGING_INFO': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PRODUCT_STEP': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer };
