import { Fetch } from 'lib';
import type { MeasurementUnit } from 'types';

type RequestResponses = {
  measurementUnits: MeasurementUnit[];
};

const getMeasurementUnits = async () =>
  await Fetch<RequestResponses>({
    url: 'adminPackagingModel/getMeasurementUnits',
  });

export { getMeasurementUnits };
