import * as React from 'react';

import { Helmet } from 'react-helmet-async';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>صفحه اصلی | کاشی لند</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>
    </>
  );
}
