import { Fetch } from 'lib';
import type { IdentitySession } from 'types';

type RequestResponses = {
  current: IdentitySession;
  others: IdentitySession[];
};

const getSessions = async () =>
  await Fetch<RequestResponses>({
    url: 'identity/getSessions',
  });

export { getSessions };
