import { useContext } from 'react';

import { AttributeActionContext, AttributeValueContext } from './context';

const useAttributeValue = () => {
  const context = useContext(AttributeValueContext);

  if (!context) {
    throw new Error(
      'useAttributeValue must be used within a <AttributeFormContext />',
    );
  }

  return context;
};

const useAttributeAction = () => {
  const context = useContext(AttributeActionContext);

  if (!context) {
    throw new Error(
      'useAttributeAction must be used within a <AttributeFormContext />',
    );
  }

  return context;
};

export { useAttributeAction, useAttributeValue };
