import { Fetch } from 'lib';
import type { AttributeConfig } from 'types';

type RequestResponses = AttributeConfig;

type RequestParameters = {
  id: string;
};

const getAttributeConfig = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminAttributeConfig/getAttributeConfig',
    params,
  });

export { getAttributeConfig };
