import { Fetch } from 'lib';
import type { AttributeConfigSortType, AttributeConfigSummary } from 'types';

type RequestResponses = {
  total: number;
  data: AttributeConfigSummary[];
};

type RequestParameters = {
  sortType: AttributeConfigSortType;
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchAttributeConfigs = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminAttributeConfig/searchAttributeConfigs',
    params,
  });

export { searchAttributeConfigs };
