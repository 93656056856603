import { initialAction, initialState } from './initial';

type Reducer = (
  state: typeof initialState,
  action: typeof initialAction,
) => typeof initialState;

const reducer: Reducer = (state, action) => {
  switch (action?.type) {
    case 'UPDATE_CATEGORY_TITLE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_CATEGORY_ICON_ID': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_CATEGORY_PARENT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_CATEGORY_RELATED_ATTRIBUTE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_CATEGORY_SUB_SLUG': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_CATEGORY_DESCRIPTION': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer };
