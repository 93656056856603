import * as React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Flex,
  Form,
  Modal,
  notification,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { usePackagingModelValue } from 'context';
import { deletePackagingModel } from 'services';
import { PATH } from 'utils';

const {
  LOCAL: { PACKAGING_MODELS },
} = PATH;

const PackagingModelFormAction = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { id = '', defaultName, mode } = usePackagingModelValue();
  const navigate = useNavigate();

  const handleDeletePackagingModel = async (id: string) => {
    deletePackagingModel({ id })
      .then(() => {
        navigate(PACKAGING_MODELS.BASE_URL);
      })
      .catch(message => {
        notification.error({ message });
      });
  };

  return (
    <div className='rounded bg-white lg:basis-[30%]'>
      <div className='px-6 pt-4'>
        <Typography.Title level={3} className='!mb-0 !text-base !font-medium'>
          انتشار بسته‌بندی
        </Typography.Title>
      </div>

      <Divider style={{ marginBlockStart: 15, marginBlockEnd: 30 }} />

      <div className='px-6'>
        <Typography.Paragraph>
          از طریق این بخش می‌توانید بسته‌بندی ساخته شده و یا ویرایش شده را منتشر
          کنید.
        </Typography.Paragraph>
      </div>

      <Flex gap='small' className='p-6 max-lg:flex-col lg:justify-end'>
        <React.Fragment>
          {mode === 'Update' ? (
            <Form.Item className='!mb-0'>
              <Button
                danger
                type='primary'
                className='!flex items-center max-lg:w-full'
                onClick={async () => {
                  const confirmed = await modal.confirm({
                    title: `آیا از حذف بسته‌بندی "${defaultName}" اطمینان دارید؟`,
                    width: 500,
                  });

                  if (confirmed) {
                    handleDeletePackagingModel(id);
                  }
                }}
              >
                <DeleteOutlined />
                <span className='text-sm'>حذف بسته‌بندی</span>
              </Button>

              {contextHolder}
            </Form.Item>
          ) : null}
        </React.Fragment>

        <Form.Item className='!mb-0'>
          <Button type='primary' htmlType='submit' className='max-lg:w-full'>
            {mode === 'Create' ? 'افزودن بسته‌بندی جدید' : 'ذخیره تغییرات'}
          </Button>
        </Form.Item>
      </Flex>
    </div>
  );
};

export { PackagingModelFormAction };
