import { Fetch } from 'lib';
import type { Brand } from 'types';

type RequestResponses = Brand;

type RequestParameters = {
  id: string;
};

const getBrand = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminBrand/getBrand',
    params,
  });

export { getBrand };
