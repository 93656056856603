import { createContext } from 'react';
import type { Dispatch } from 'react';

import { initialAction, initialState } from './initial';

const AttributeValueContext = createContext<typeof initialState>(initialState);

const AttributeActionContext = createContext<Dispatch<typeof initialAction>>(
  () => {},
);

export { AttributeActionContext, AttributeValueContext };
