import { Fetch } from 'lib';

type RequestResponses = {};

type RequestParameters = {
  id: string;
};

type RequestBody = {};

const sendUserForgetPasswordLink = async (
  params: RequestParameters,
  body: RequestBody,
) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminUser/sendUserForgetPasswordLink',
    method: 'POST',
    params,
    body,
  });

export { sendUserForgetPasswordLink };
