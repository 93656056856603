import { Fetch } from 'lib';

type RequestResponses = {};

type RequestParameters = {
  id: string;
};

const deleteProductComment = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminProductComment/deleteProductComment',
    method: 'DELETE',
    params,
  });

export { deleteProductComment };
