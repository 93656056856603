import * as React from 'react';

import { useSearchParams } from 'react-router-dom';

import { LoginDefaultForm } from './default';
import { LoginOTPForm } from './otp';

const LoginForm = () => {
  const [searchParams] = useSearchParams();
  const isOTPForm = searchParams.get('type') === 'otp';

  return isOTPForm ? <LoginOTPForm /> : <LoginDefaultForm />;
};

export { LoginForm };
