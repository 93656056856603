import { Fetch } from 'lib';
import type {
  BaseAttributeSummary,
  Product,
  ProductFileInfoSummary,
  ProductPackagingSummary,
  SubProductSummary,
} from 'types';

type RequestResponses = Product;

type RequestParameters = {};

type RequestBody = Pick<
  Product,
  | 'name'
  | 'slug'
  | 'identifier'
  | 'callForPrice'
  | 'showPostingStatus'
  | 'description'
  | 'status'
  | 'productType'
  | 'seo'
> &
  Partial<Record<'mainCategoryId' | 'brandId', string>> &
  Record<'otherCategoriesIds' | 'variableAttributesIds', string[]> &
  Record<'subProducts', SubProductSummary[]> &
  Record<'baseAttributes', BaseAttributeSummary[]> &
  Record<'packagingInfo', ProductPackagingSummary[]> &
  Record<'pictures' | 'documents', ProductFileInfoSummary[]>;

const createProduct = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminProduct/createProduct',
    method: 'POST',
    body,
  });

export { createProduct };
