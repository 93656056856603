import * as React from 'react';

import { useRequest } from 'ahooks';
import { Helmet } from 'react-helmet-async';

import { getCategories } from 'services';

import {
  CategoriesHeader,
  CategoriesOrder,
  CategoriesTable,
} from './components';

export default function Categories() {
  const { data, loading, refresh } = useRequest(getCategories, {
    cacheKey: 'categories',
  });

  const categories = data?.categories ?? [];

  return (
    <>
      <Helmet>
        <title>صفحه دسته‌بندی‌ها | کاشی لند</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <div className='rounded bg-white p-6'>
        <CategoriesHeader>
          <CategoriesOrder
            loading={loading}
            categories={categories}
            handleRefreshData={refresh}
          />
        </CategoriesHeader>

        <CategoriesTable
          loading={loading}
          categories={categories}
          handleRefreshData={refresh}
        />
      </div>
    </>
  );
}
