import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { CategoryFormContext } from 'context';
import type { Category as CategoryConfig, ComponentMode } from 'types';

import { CategoryForm, CategoryHeader } from './components';

type CategoryProps = {
  mode: ComponentMode;
};

export default function Category({ mode }: CategoryProps) {
  const categoryData = useLoaderData() as CategoryConfig;

  const isUpdatedCategory = mode === 'Update';
  const categoryTitle = isUpdatedCategory
    ? categoryData.title
    : 'افزودن دسته‌بندی‌ جدید';
  const defaultTitle = isUpdatedCategory ? categoryData.title : '';

  return (
    <>
      <Helmet>
        <title>{`صفحه ${categoryTitle} | کاشی لند`}</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <CategoryHeader>{categoryTitle}</CategoryHeader>

      <CategoryFormContext
        mode={mode}
        defaultTitle={defaultTitle}
        {...(isUpdatedCategory && categoryData)}
      >
        <CategoryForm />
      </CategoryFormContext>
    </>
  );
}
