import type { ComponentMode, PackagingModel } from 'types';

type State = Partial<
  PackagingModel & Record<'mode', ComponentMode> & Record<'defaultName', string>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_PACKAGING_MODEL_CATEGORIES';
      payload: Pick<typeof initialState, 'categories'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
