import { useContext } from 'react';

import { CategoryActionContext, CategoryValueContext } from './context';

const useCategoryValue = () => {
  const context = useContext(CategoryValueContext);

  if (!context) {
    throw new Error(
      'useCategoryValue must be used within a <CategoryFormContext />',
    );
  }

  return context;
};

const useCategoryAction = () => {
  const context = useContext(CategoryActionContext);

  if (!context) {
    throw new Error(
      'useCategoryAction must be used within a <CategoryFormContext />',
    );
  }

  return context;
};

export { useCategoryAction, useCategoryValue };
