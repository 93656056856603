import * as React from 'react';

import { BrandActionContext, BrandValueContext } from './context';
import { useBrandAction, useBrandValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const BrandFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <BrandValueContext.Provider value={state}>
      <BrandActionContext.Provider value={dispatch}>
        {children}
      </BrandActionContext.Provider>
    </BrandValueContext.Provider>
  );
};

export { BrandFormContext, useBrandAction, useBrandValue };
