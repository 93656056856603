import { createContext } from 'react';
import type { Dispatch } from 'react';

import { initialAction, initialState } from './initial';

const PackagingModelValueContext =
  createContext<typeof initialState>(initialState);

const PackagingModelActionContext = createContext<
  Dispatch<typeof initialAction>
>(() => {});

export { PackagingModelActionContext, PackagingModelValueContext };
