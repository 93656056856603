import { Fetch } from 'lib';
import type { AttributeConfig, AttributeConfigCategorySummary } from 'types';

type RequestResponses = AttributeConfig;

type RequestParameters = {};

type RequestBody = Pick<
  AttributeConfig,
  'name' | 'abstraction' | 'isFilter' | 'type'
> &
  Record<'categories', AttributeConfigCategorySummary[]>;

const createAttributeConfig = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminAttributeConfig/createAttributeConfig',
    method: 'POST',
    body,
  });

export { createAttributeConfig };
