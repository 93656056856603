import { useCountDown, useGetState, useMemoizedFn } from 'ahooks';

import { padWithZero } from 'utils';

const useTimer = ({
  targetDate: defaultTargetDate,
  ...options
}: NonNullable<Parameters<typeof useCountDown>[0]>) => {
  const [targetDate, setTargetDate] = useGetState(defaultTargetDate);

  const [totalSeconds, { milliseconds, seconds, minutes, hours, days }] =
    useCountDown({
      targetDate,
      ...options,
    });

  const restart = useMemoizedFn((date: typeof targetDate) => {
    setTargetDate(date);
  });

  const reset = useMemoizedFn(() => {
    setTargetDate(undefined);
  });

  return {
    milliseconds: padWithZero(milliseconds.toString(), 2),
    seconds: padWithZero(seconds.toString(), 2),
    minutes: padWithZero(minutes.toString(), 2),
    hours: padWithZero(hours.toString(), 2),
    days: padWithZero(days.toString(), 2),
    totalSeconds: padWithZero(totalSeconds.toString(), 2),
    isRunning: totalSeconds !== 0,
    restart,
    reset,
  };
};

export { useTimer };
