import * as React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';

import { AntdRouterLink } from 'components';
import { PATH } from 'utils';

const {
  LOCAL: { CATEGORIES },
} = PATH;

const CategoriesHeader = ({ children }: React.PropsWithChildren<unknown>) => (
  <Flex
    gap={10}
    className='mb-5 max-lg:flex-col lg:items-center lg:justify-between'
  >
    <Typography.Title level={2} className='py-1' style={{ marginBlockEnd: 0 }}>
      <span className='text-base'>دسته‌بندی‌ها</span>
    </Typography.Title>

    <Flex gap={15} className='max-lg:flex-wrap'>
      {children}

      <AntdRouterLink to={CATEGORIES.CREATE_URL} className='max-lg:basis-full'>
        <Button
          type='primary'
          className='w-full'
          icon={<PlusOutlined style={{ fontSize: 12 }} />}
        >
          دسته‌بندی‌ جدید
        </Button>
      </AntdRouterLink>
    </Flex>
  </Flex>
);

export { CategoriesHeader };
