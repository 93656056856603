import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { BrandFormContext } from 'context';
import type { Brand as BrandConfig, ComponentMode } from 'types';

import { BrandForm, BrandHeader } from './components';

type BrandProps = {
  mode: ComponentMode;
};

export default function Brand({ mode }: BrandProps) {
  const brandData = useLoaderData() as BrandConfig;

  const isUpdatedBrand = mode === 'Update';
  const brandTitle = isUpdatedBrand ? brandData.title : 'افزودن برند جدید';
  const defaultTitle = isUpdatedBrand ? brandData.title : '';

  return (
    <>
      <Helmet>
        <title>{`صفحه ${brandTitle} | کاشی لند`}</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <BrandHeader>{brandTitle}</BrandHeader>

      <BrandFormContext
        mode={mode}
        defaultTitle={defaultTitle}
        {...(isUpdatedBrand && brandData)}
      >
        <BrandForm />
      </BrandFormContext>
    </>
  );
}
