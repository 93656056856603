import { Fetch } from 'lib';
import type { UserSortType, UserSummary } from 'types';

type RequestResponses = {
  total: number;
  data: UserSummary[];
};

type RequestParameters = {
  sortType: UserSortType;
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchUsers = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminUser/searchUsers',
    params,
  });

export { searchUsers };
