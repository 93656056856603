import * as React from 'react';

import { Col, Form, Input, Row } from 'antd';

import { useProductValue } from 'context';
import { inputRule } from 'utils';

type FieldType = Partial<Record<'seoTitle' | 'seoDescription', string>>;

const ProductSeoInfo = () => {
  const { seo } = useProductValue();

  return (
    <Row>
      <Col span={24}>
        <Form.Item<FieldType>
          label='عنوان'
          name='seoTitle'
          extra='این نوشته در خط اول در نتایج جستجو نمایش داده خواهد شد.'
          rules={[inputRule.string, inputRule.seoTitle]}
          initialValue={seo?.title}
        >
          <Input placeholder='عنوان را برای سئو وارد کنید' />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<FieldType>
          label='توضیحات'
          name='seoDescription'
          extra='این همان توضیحی است که هنگام نمایش در نتایج جستجو دیده می‌شود.'
          rules={[inputRule.string, inputRule.seoDescription]}
          initialValue={seo?.description}
        >
          <Input.TextArea rows={6} placeholder='توضیحات را وارد کنید' />
        </Form.Item>
      </Col>
    </Row>
  );
};

export { ProductSeoInfo };
