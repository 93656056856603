import { Upload } from 'lib';

type RequestBody = Omit<Parameters<typeof Upload>[number], 'url'>;

const uploadUserFile = async (body: RequestBody) =>
  await Upload({
    url: 'adminUser/uploadUserFile',
    ...body,
  });

export { uploadUserFile };
