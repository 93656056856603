import { Fetch } from 'lib';
import type { IdentityPayload } from 'types';

type RequestResponses = {};

type RequestParameters = {};

type RequestBody = Pick<
  IdentityPayload,
  'phoneNumber' | 'email' | 'token' | 'tokenType' | 'newPassword'
>;

const resetPassword = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/resetPassword',
    method: 'PATCH',
    body,
  });

export { resetPassword };
