import { Fetch } from 'lib';

type RequestResponses = {};

type RequestParameters = {
  id: string;
};

const deletePackagingModel = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminPackagingModel/deletePackagingModel',
    method: 'DELETE',
    params,
  });

export { deletePackagingModel };
