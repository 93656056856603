import { initialAction, initialState } from './initial';

type Reducer = (
  state: typeof initialState,
  action: typeof initialAction,
) => typeof initialState;

const reducer: Reducer = (state, action) => {
  switch (action?.type) {
    case 'UPDATE_LOGIN_PHONE_NUMBER': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_LOGIN_TICKET': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer };
