import { useContext } from 'react';

import { BrandActionContext, BrandValueContext } from './context';

const useBrandValue = () => {
  const context = useContext(BrandValueContext);

  if (!context) {
    throw new Error('useBrandValue must be used within a <BrandFormContext />');
  }

  return context;
};

const useBrandAction = () => {
  const context = useContext(BrandActionContext);

  if (!context) {
    throw new Error(
      'useBrandAction must be used within a <BrandFormContext />',
    );
  }

  return context;
};

export { useBrandAction, useBrandValue };
