import * as React from 'react';

import { Typography } from 'antd';
import { useHref, useLinkClickHandler } from 'react-router-dom';

const { Link } = Typography;

type AntdRouterLinkProps = Omit<
  React.ComponentPropsWithoutRef<typeof Link>,
  'href'
> &
  Record<'to', Parameters<typeof useLinkClickHandler>[0]> &
  DeepPartial<
    Record<'handlerProps', Parameters<typeof useLinkClickHandler>[1]>
  >;

const AntdRouterLink = React.forwardRef<
  React.ElementRef<typeof Link>,
  AntdRouterLinkProps
>(({ to, handlerProps, onClick, ...props }, ref) => {
  const href = useHref(to, { relative: handlerProps?.relative });
  const handleClick = useLinkClickHandler(to, handlerProps);

  return (
    <Link
      ref={ref}
      href={href}
      target={handlerProps?.target}
      onClick={event => {
        if (!event.defaultPrevented) {
          handleClick(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
        }

        onClick?.(event);
      }}
      {...props}
    />
  );
});
AntdRouterLink.displayName = 'AntdRouterLink';

export { AntdRouterLink };
