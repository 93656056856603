import { Fetch } from 'lib';
import { createProduct } from 'services';

type RequestResponses = Awaited<ReturnType<typeof createProduct>>;

type RequestParameters = {
  id: string;
};

type RequestBody = Parameters<typeof createProduct>[number];

const updateProduct = async (params: RequestParameters, body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'adminProduct/updateProduct',
    method: 'PUT',
    params,
    body,
  });

export { updateProduct };
