import { Fetch } from 'lib';

type RequestResponses = {};

type RequestParameters = {
  id: string;
};

const deleteAttributeConfig = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminAttributeConfig/deleteAttributeConfig',
    method: 'DELETE',
    params,
  });

export { deleteAttributeConfig };
