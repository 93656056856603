/* eslint-disable react/prop-types */
import * as React from 'react';

import { FileImageOutlined } from '@ant-design/icons';
import { Flex, Typography, Upload } from 'antd';

import { cn, FILE, validateMediaFile } from 'utils';

const { Dragger } = Upload;
const {
  TYPES: { IMAGES, MOVIES, DOCUMENTS },
} = FILE;

type DraggerUploadProps = React.ComponentPropsWithoutRef<typeof Dragger> &
  Partial<Record<'withImages' | 'withMovies' | 'withDocuments', boolean>>;

const DraggerUpload = React.forwardRef<
  React.ElementRef<typeof Dragger>,
  DraggerUploadProps
>(
  (
    {
      multiple = true,
      showUploadList = false,
      withImages = true,
      withMovies = true,
      withDocuments = true,
      className,
      ...props
    },
    ref,
  ) => (
    <Dragger
      ref={ref}
      multiple={multiple}
      showUploadList={showUploadList}
      className={cn('first:[&>div]:!border-solid', className)}
      beforeUpload={({ type }) =>
        (withImages && validateMediaFile(type, IMAGES)) ||
        (withMovies && validateMediaFile(type, MOVIES)) ||
        (withDocuments && validateMediaFile(type, DOCUMENTS)) ||
        Upload.LIST_IGNORE
      }
      {...props}
    >
      <FileImageOutlined
        style={{
          color: 'var(--ant-color-link-hover)',
          fontSize: 22,
          marginBlock: '0.75rem',
        }}
      />

      <Typography.Paragraph>
        تصاویر، ویدیوها و فایل‌های محصول را اینجا بارگذاری کنید
      </Typography.Paragraph>

      <Flex
        vertical
        justify='center'
        align='center'
        className='mt-1 text-gray-400'
      >
        {withImages ? <p>فرمت قابل قبول تصاویر: {IMAGES.join(',')}</p> : null}
        {withMovies ? <p>فرمت قابل قبول ویدیوها: {MOVIES.join(',')}</p> : null}
        {withDocuments ? (
          <p>فرمت قابل قبول فایل‌ها:‌ {DOCUMENTS.join(',')}</p>
        ) : null}
      </Flex>
    </Dragger>
  ),
);
DraggerUpload.displayName = 'DraggerUpload';

export { DraggerUpload };
