import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { PackagingModelFormContext } from 'context';
import type {
  ComponentMode,
  PackagingModel as PackagingModelConfig,
} from 'types';

import { PackagingModelForm, PackagingModelHeader } from './components';

type PackagingModelProps = {
  mode: ComponentMode;
};

export default function PackagingModel({ mode }: PackagingModelProps) {
  const packagingModelData = useLoaderData() as PackagingModelConfig;

  const isUpdatedPackagingModel = mode === 'Update';
  const packagingModelTitle = isUpdatedPackagingModel
    ? packagingModelData.name
    : 'افزودن بسته‌بندی جدید';
  const defaultName = isUpdatedPackagingModel ? packagingModelData.name : '';

  return (
    <>
      <Helmet>
        <title>{`صفحه ${packagingModelTitle} | کاشی لند`}</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <PackagingModelHeader>{packagingModelTitle}</PackagingModelHeader>

      <PackagingModelFormContext
        mode={mode}
        defaultName={defaultName}
        {...(isUpdatedPackagingModel && packagingModelData)}
      >
        <PackagingModelForm />
      </PackagingModelFormContext>
    </>
  );
}
