import type {
  ComponentMode,
  ProductComment,
  ProductCommentStatus,
} from 'types';

type State = Partial<
  ProductComment &
    Record<'mode', ComponentMode> &
    Record<'defaultName', string> &
    Record<'defaultStatus', ProductCommentStatus>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_PRODUCT_COMMENT_CONTENT';
      payload: Pick<typeof initialState, 'content'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
