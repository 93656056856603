import { Fetch } from 'lib';

type RequestResponses = {};

type RequestParameters = {};

type RequestBody = {};

const signOut = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/signOut',
    method: 'PATCH',
    body,
  });

export { signOut };
