import { Fetch } from 'lib';
import type { IdentityPayload, IdentityToken } from 'types';

type RequestResponses = IdentityToken;

type RequestParameters = {};

type RequestBody = Pick<
  IdentityPayload,
  'phoneNumber' | 'password' | 'platform'
>;

const signinWithPassword = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/signInWithPassword',
    method: 'POST',
    body,
  });

export { signinWithPassword };
