import { Fetch } from 'lib';
import type { IdentityPayload } from 'types';

type RequestResponses = {};

type RequestParameters = {};

type RequestBody = Pick<IdentityPayload, 'otp'>;

const confirmPhoneNumber = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/confirmPhoneNumber',
    method: 'POST',
    body,
  });

export { confirmPhoneNumber };
