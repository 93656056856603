import * as React from 'react';

import { useLoginValue } from 'context';

import { PhoneNumberForm } from './phone-number';
import { PinForm } from './pin';

const LoginOTPForm = () => {
  const { phoneNumber } = useLoginValue();

  return phoneNumber ? <PinForm /> : <PhoneNumberForm />;
};

export { LoginOTPForm };
