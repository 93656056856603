import * as React from 'react';

import { ProductActionContext, ProductValueContext } from './context';
import { useProductAction, useProductValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const ProductFormContext = ({
  children,
  ...props
}: React.PropsWithChildren<typeof initialState>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    ...props,
  });

  return (
    <ProductValueContext.Provider value={state}>
      <ProductActionContext.Provider value={dispatch}>
        {children}
      </ProductActionContext.Provider>
    </ProductValueContext.Provider>
  );
};

export { ProductFormContext, useProductAction, useProductValue };
