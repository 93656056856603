import { Fetch } from 'lib';

type RequestResponses = {};

const checkAuth = async () =>
  await Fetch<RequestResponses>({
    url: 'identity/checkAuth',
  });

export { checkAuth };
