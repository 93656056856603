import { Fetch } from 'lib';
import type { IdentityPayload } from 'types';

type RequestResponses = Pick<IdentityPayload, 'ticket'>;

type RequestParameters = {};

type RequestBody = Pick<IdentityPayload, 'phoneNumber'>;

const sendSigninOtp = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/sendSignInOtp',
    method: 'POST',
    body,
  });

export { sendSigninOtp };
