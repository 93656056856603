import { createContext } from 'react';
import type { Dispatch } from 'react';

import { initialAction, initialState } from './initial';

const ProductCommentValueContext =
  createContext<typeof initialState>(initialState);

const ProductCommentActionContext = createContext<
  Dispatch<typeof initialAction>
>(() => {});

export { ProductCommentActionContext, ProductCommentValueContext };
