/* eslint-disable react/prop-types */
import * as React from 'react';

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

import { cn } from 'utils';

const DocumentViewer = React.forwardRef<
  React.ElementRef<typeof DocViewer>,
  React.ComponentPropsWithoutRef<typeof DocViewer>
>(
  (
    {
      pluginRenderers = DocViewerRenderers,
      config = {
        header: {
          disableHeader: true,
          disableFileName: true,
        },
        pdfZoom: {
          defaultZoom: 1.8,
          zoomJump: 1,
        },
      },
      className,
      ...props
    },
    ref,
  ) => (
    <DocViewer
      ref={ref}
      pluginRenderers={pluginRenderers}
      config={config}
      className={cn(
        'max-h-56 overflow-y-auto [&_#image-renderer]:bg-[inherit] [&_#image-renderer]:bg-none [&_#pdf-controls]:hidden',
        className,
      )}
      {...props}
    />
  ),
);
DocumentViewer.displayName = 'DocumentViewer';

export { DocumentViewer };
