import { Fetch } from 'lib';
import type { ProductComment } from 'types';

type RequestResponses = ProductComment;

type RequestParameters = {
  id: string;
};

type RequestBody = Pick<ProductComment, 'content' | 'status'>;

const editProductComment = async (
  params: RequestParameters,
  body: RequestBody,
) =>
  await Fetch<
    RequestResponses,
    Omit<RequestParameters, 'commentId '>,
    RequestBody
  >({
    url: 'adminProductComment/editProductComment',
    method: 'PUT',
    params,
    body,
  });

export { editProductComment };
