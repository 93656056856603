import { Upload } from 'lib';

type RequestBody = Omit<Parameters<typeof Upload>[number], 'url'>;

const uploadProductFile = async (body: RequestBody) =>
  await Upload({
    url: 'adminProduct/uploadProductFile',
    ...body,
  });

export { uploadProductFile };
