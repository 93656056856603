import { Fetch } from 'lib';
import type { IdentityPayload, IdentityToken } from 'types';

type RequestResponses = IdentityToken;

type RequestParameters = {};

type RequestBody = Pick<IdentityPayload, 'otp' | 'ticket' | 'platform'>;

const signinWithOtp = async (body: RequestBody) =>
  await Fetch<RequestResponses, RequestParameters, RequestBody>({
    url: 'identity/signInWithOtp',
    method: 'POST',
    body,
  });

export { signinWithOtp };
