import * as React from 'react';

import useUrlState from '@ahooksjs/use-url-state';
import { useGetState, useMemoizedFn, useRequest } from 'ahooks';
import { Helmet } from 'react-helmet-async';

import { searchPackagingModels } from 'services';
import { PATH } from 'utils';

import { PackagingModelsHeader, PackagingModelsTable } from './components';

const {
  QUERY_PARAM: {
    DEFAULT: {
      SORT_TYPE: DEFAULT_SORT_TYPE,
      PAGE_NUMBER: DEFAULT_PAGE_NUMBER,
      PAGE_SIZE: DEFAULT_PAGE_SIZE,
    },
    SORT_TYPE: { BASE_URL: SORT_TYPE_BASE_URL },
    SEARCH_KEY: { BASE_URL: SEARCH_KEY_BASE_URL },
    PAGE_NUMBER: { BASE_URL: PAGE_NUMBER_BASE_URL },
  },
} = PATH;

export default function PackagingModels() {
  const [urlState, setUrlState] = useUrlState(
    {
      [SORT_TYPE_BASE_URL]: DEFAULT_SORT_TYPE,
      [SEARCH_KEY_BASE_URL]: '',
      [PAGE_NUMBER_BASE_URL]: DEFAULT_PAGE_NUMBER,
    },
    { navigateMode: 'replace' },
  );
  const [pageSize, setPageSize] = useGetState<number>(DEFAULT_PAGE_SIZE);

  const { data, loading, refresh } = useRequest(
    async () =>
      await searchPackagingModels({
        sortType: urlState[SORT_TYPE_BASE_URL],
        searchKey: urlState[SEARCH_KEY_BASE_URL],
        pageNumber: +urlState[PAGE_NUMBER_BASE_URL],
        pageSize,
      }),
    {
      refreshDeps: [
        urlState[SORT_TYPE_BASE_URL],
        urlState[SEARCH_KEY_BASE_URL],
        urlState[PAGE_NUMBER_BASE_URL],
        pageSize,
      ],
    },
  );

  const handleSearchKey = useMemoizedFn((searchKey: string) => {
    setUrlState({ [SEARCH_KEY_BASE_URL]: searchKey });
  });
  const handlePageNumber = useMemoizedFn((pageNumber: number) => {
    setUrlState({ [PAGE_NUMBER_BASE_URL]: pageNumber });
  });
  const handlePageSize = useMemoizedFn((pageSize: number) => {
    setPageSize(pageSize);
  });

  const total = data?.total ?? 0;
  const packagingModels = data?.data ?? [];

  return (
    <>
      <Helmet>
        <title>صفحه بسته‌بندی‌ها | کاشی لند</title>
        <link rel='canonical' href='https://www.tacobell.com/' />
      </Helmet>

      <div className='rounded bg-white p-6'>
        <PackagingModelsHeader
          total={total}
          searchKey={urlState[SEARCH_KEY_BASE_URL]}
          handleSearchKey={handleSearchKey}
        />

        <PackagingModelsTable
          loading={loading}
          total={total}
          packagingModels={packagingModels}
          pageSize={pageSize}
          pageNumber={+urlState[PAGE_NUMBER_BASE_URL]}
          handlePageSize={handlePageSize}
          handlePageNumber={handlePageNumber}
          handleRefreshData={refresh}
        />
      </div>
    </>
  );
}
