import { Fetch } from 'lib';
import type { ProductComment, ProductCommentSortType } from 'types';

type RequestResponses = {
  total: number;
  data: ProductComment[];
};

type RequestParameters = {
  sortType: ProductCommentSortType;
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchProductsComments = async (params: RequestParameters) =>
  await Fetch<RequestResponses, RequestParameters>({
    url: 'adminProductComment/searchProductsComments',
    params,
  });

export { searchProductsComments };
