import * as React from 'react';

import WashBasin from 'assets/images/wash-basin.png';
import { LazyLoadImage } from 'components';

const LoginWallpaper = () => (
  <LazyLoadImage
    src={WashBasin}
    className='h-dvh w-full'
    alt='عکس روشویی برای صفحه ورود'
  />
);

export { LoginWallpaper };
